import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDispatch, useSelector} from "react-redux";
import {SvgImages} from "../../constants/SvgImages";
import {
  getAllServices,
  otpNotVerifiedForReducer,
  signup,
  stepperFormSelected,
} from "../../feature/slice";
import {toast} from "react-toastify";
import Loader from "../../component/Loader";
import {useGoogleLogin} from '@react-oauth/google';
import axios from "axios";
import NewSignup2Form from "./NewSignup2Form";


function NewSigninForm({handleShow}) {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_type = useSelector((state) => state.user_type);

  // const stepperFormSelected = useSelector((state) => state.stepperFormSelected);
  const [data, setData] = useState(false);

  const [toggle, setToggle] = useState(false);
  const loading = useSelector((state) => state.loading);


  useEffect(() => {
    dispatch(getAllServices({user_type: "counseller"}));
    dispatch(getAllServices({user_type: "mentor"}));
  }, [dispatch, user_type]);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter valid email address.")
      .required("Required"),
    // username: yup.string().required("Required"),
    password: yup.string().required("Required").min(8).max(32),
    section: yup.string().required("Required").typeError("Required"),
    tnc: yup
      .boolean()
      .oneOf(
        [true],
        "Kindly accept the terms & conditions and privacy policy."
      ),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: {errors, dirtyFields},
  } = useForm({
    mode: "onBlur",
    shouldFocusError: "true",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),

  });
  var emailValue = watch("email", " ");

  let userNameData;
  const setUserNameByEmail = () => {
    let randomNumber = Math.floor(Math.random() * 99999);
    let userVal = emailValue.substring(0, emailValue.indexOf("@"));
    userNameData = `${userVal}${randomNumber}`
    setValue('username', userNameData);
    setData(true);

  };
  // console.log(data, "ooo");
  var userName = watch("username", " ");
  var userNameValue = data ? userName : emailValue.substring(0, emailValue.indexOf("@"));

  // console.log("emailValue",userName === data, userName );
  const onSubmit = (data) => {
    // console.log("data",data);
    dispatch(
      signup({
        email: data.email.trim(),
        password: data.password,
        user_type: data.section,
        // username: emailValue.substring(0,emailValue.indexOf("@")),
        username: userNameValue.trim()
        // username: data?.username.trim(),

      })
    ).then(async (res) => {
      // console.log(res, "Sign up");
      if(res?.payload?.code === 1) {
        window.localStorage.setItem("username", emailValue.substring(0, emailValue.indexOf("@")));
        // setModalName("otpVerification");
        dispatch(otpNotVerifiedForReducer("signup"));
        // setUserType(data?.section);

        toast.success(res?.payload?.message);
      } else {
        // console.log(res?.payload?.message);
        toast.error(res?.payload?.message);
        setUserNameByEmail()
      }
    });
  };
  // console.log("useer", userNameValue);
  const noBaseAxios = axios.create();
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await noBaseAxios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        console.log("google_response", res.data);
        let email = res.data?.email;
        setValue('email', email);
        email ? setValue('username', email.substring(0, email.indexOf("@"))) : setValue('username', res?.data?.given_name)
        // let data = {       
        //   email: res.data.email,        
        //   user_type: register.user_type,       
        //   signup_type: "google",        // };       
        // handleRegister(data);      
      } catch(error) {
        console.log("error", error);
        // setAlert("Somthing went wrong");
      }
    },
    onError: (err) => console.log("err", err),
  });
  console.log('get value email', getValues('section'))
  return (
    <>
      <form
        className=""
      // onSubmit={handleSubmit(onSubmit)}
      >

        <div className="row mb-3">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="form-group mb-1">
              <span className="signup_user_txt">Email Address/Username</span>

              <input
                className="form-control signup_input"
                placeholder="Enter Email/Username"
                {...register("email")}
              />
              {/* <span className="text-danger">{errors?.email?.message}</span> */}
              {/* {getValues('email') ? <span className="text-danger">{errors?.email?.message}</span>: ''} */}
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12  col-lg-12">
            <div className="form-group mb-1">
              <span className="signup_user_txt">Password</span>
              <input
                type={toggle ? "text" : "password"}
                className="form-control signup_input"
                id="password-field1"
                placeholder="Enter Password"
                {...register("password")}
              />
              {/* <span className="text-danger">{errors?.password?.message}</span> */}
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-12 col-sm-12 col-md-12  col-lg-12">
            <div className="form-group mb-2 text-center">
              <button
                // type="submit"
                // to="/new-sign-up/2"
                //   onClick={() => {
                //     dispatch(stepperFormSelected(1))
                //   }}
                className="simple_signup_btn cursor-pointer"
              >
                {loading ? <Loader /> : "Sign In"}
              </button>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="form-group mb-2 text-center">
              <button
                type="submit"
                // to="/sign-up/1"
                // onClick={() => {
                //   handleShow();
                //   setModalName("");
                // }}
                onClick={() => handleGoogleLogin()}
                className="google_signup_btn cursor-pointer"
              >
                {loading ? <Loader /> : <div>
                  <img src={SvgImages.SignupGoogleIcon} style={{marginBottom: '5px'}} alt="" />
                  <span className="pl-2">Continue with Google</span>
                </div>}
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="form-group already_have_account text-center">
              <span style={{color: "#000000"}}>Don't have an account? </span>
              <span
                className="theme-color cursor-pointer"
                onClick={() => {
                  dispatch(stepperFormSelected(0))
                }}
              >
                Sign-up
              </span>
            </div>
          </div>
        </div>

      </form>
    </>
  );
}


export default NewSigninForm