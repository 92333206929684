import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import {useDispatch, useSelector} from "react-redux";
import {
  addMentorDetails,
  getAllCareerList,
  getAllCityList,
  getAllServiceList,
  getAllSkillList,
  getAllStateList,
  otpForReducer,
  otpNotVerifiedForReducer,
  setModalName,
  setOtpShow,
  signInModal,
  toggleSignInModal,
} from "../slice";
import SignIn from "../../component/modal/SignIn";
import {uploadFile} from "react-s3";
import {Progress} from "react-sweet-progress";
import parsePhoneNumber from "libphonenumber-js";
import Select from "react-select";
import {toast} from "react-toastify";
import Loader from "../../component/Loader";

function MentorSignup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const loading = useSelector((state) => state.loading);
  const country = useSelector((state) => state.country);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [career_paths, setCareer_paths] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const {pathname} = useLocation();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const email = useSelector((state) => state.email);
  const username = localStorage.getItem("username");
  const show = useSelector(signInModal);

  const handleShow = () => {
    dispatch(toggleSignInModal(!show));
  };

  const customStyles = {
    control: (styles) => ({...styles, borderWidth: "0", minHeight: "35px"}),
    menu: (styles) => ({...styles, width: "90%"}),
    valueContainer: (styles) => ({
      ...styles,
      height: "35px",
      overflowY: "scroll",
    }),
  };
  const schema = yup.object().shape({
    primaryEmail: yup
      .string()
      .email("Kiundly enter a valid email.")
      .required("Required"),
    secondaryEmail: yup
      .string()
      .email("Kindly enter a valid email.")
      .nullable()
      .notRequired(),
    gender: yup
      .string()
      .typeError("Kindly select a gender.")
      .required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    designation: yup.string().required("Required"),
    companyName: yup.string().required("Required"),

    about: yup.string().required("Required"),
    city: yup.string().typeError("Required").required("Required"),
    state: yup.string().typeError("Required").required("Required"),
    country: yup.string().typeError("Required").required("Required"),
    image: yup.string().required("Required"),
    experience: yup
      .number()
      .positive()
      .integer()
      .typeError("Kindly enter a valid number.")
      .required("Required"),
    primaryNumber: yup
      .number()
      .positive()
      .integer()
      .typeError("Kindly enter a valid phone number.")
      .required("Required"),
    countryCode: yup
      .number()
      .positive()
      .integer()
      .typeError("Kindly enter a valid phone number."),
    secondaryNumber: yup
      .number()
      .positive()
      .integer()
      .typeError("Kindly enter a valid phone number.")
      .nullable(),
    secondaryCountryCode: yup
      .number()
      .positive()
      .integer()
      .typeError("Kindly enter a valid phone number.")
      .nullable(),
    career_data: yup
      .array()
      .of(
        yup.object().shape({
          career_id: yup.string(),
        })
      )
      .min(1, "Required")
      .typeError("Required"),
    service_data: yup.array().of(
      yup.object().shape({
        service_id: yup
          .object()
          .shape({
            id: yup.string(),
            title: yup.string(),
          })
          .required("Required")
          .typeError("Required"),
        fee: yup
          .number()
          .integer()
          .positive()
          .required("Required")
          .typeError("Kindly enter a valid number."),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
    getValues,
    control,
    setValue,
  } = useForm({
    mode: "onBlur",
    shouldFocusError: "true",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      service_data: [{service_id: "", fee: ""}],
      primaryEmail: isLoggedIn ? email : "",
    },
  });

  const {append, fields, remove} = useFieldArray({
    control: control,
    name: "service_data",
  });
  const onSubmit = (data) => {
    console.log(data);

    const serviceDatas = data?.service_data?.map((item) => {
      return {service_id: item?.service_id?.id, fee: item?.fee.toString()};
    });
    console.log(data?.career_data);
    const careerDatas = data?.career_data?.map((item) => {
      return {career_id: item?.id};
    });

    dispatch(
      addMentorDetails({
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.primaryEmail,
        gender: data?.gender,
        mobile: data?.primaryNumber,
        countryCode: data?.countryCode,
        secondary_mobile: data?.secondaryNumber ? data?.secondaryNumber : "",
        secondary_countryCode: data?.secondaryCountryCode
          ? data?.secondaryCountryCode
          : "",
        designation: data?.designation,
        company_name: data?.companyName,
        secondary_email: data?.secondaryEmail,
        about: data?.about,
        country: data?.country,
        state: data?.state,
        city: data?.city,
        image: data?.image,
        experience: data?.experience,
        career_data: JSON.stringify(careerDatas),
        service_data: JSON.stringify(serviceDatas),
        username,
      })
    ).then((res) => {
      console.log(res, "Counseller");
      if(res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        // navigate("/", { replace: true });
        dispatch(setOtpShow(true));
        dispatch(otpForReducer("mobile verification"));
        dispatch(setModalName("otpVerification"));
        dispatch(otpNotVerifiedForReducer("signup"));
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  console.log(errors);

  const handleCountry = (name, value) => {
    setValue(name, value);
  };

  const getState = useCallback(
    (val) => {
      dispatch(getAllStateList({countryIsoCode: val})).then((res) => {
        if(res?.payload?.code === 1) {
          setStates(res?.payload?.data?.state_list);
        }
      });
    },
    [dispatch]
  );

  const getCity = useCallback(
    (val) => {
      dispatch(getAllCityList({state_code: val})).then((res) => {
        if(res?.payload?.code === 1) {
          console.log(res);
          setCity(res?.payload?.data?.city_list);
        }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    let interval;
    if(isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };
  const handleImage = (e) => {
    const {files} = e.target;
    setIsActive(true);

    uploadFile(files[0], config)
      .then((data) => {
        setValue("image", data?.location);
        setTime(100);
        setIsActive(true);
        setTimeout(() => {
          setTime(0);
          setIsActive(false);
        }, 2000);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    dispatch(getAllCareerList({})).then((res) => {
      if(res?.payload?.code === 1) {
        const newArr = res?.payload?.data?.career_list?.map((item) => {
          return {id: item?.id, title: item?.title};
        });

        setCareer_paths(newArr);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getAllServiceList({user_type: "mentor"})).then((res) => {
      if(res?.payload?.code === 1) {
        console.log(res);
        setServiceList(
          res?.payload?.data?.service_list?.map((it) => {
            return {id: it.id, title: it?.title};
          })
        );
      }
    });
  }, []);

  console.log(watch("service_data"));
  const serviceData = watch(`service_data`);

  const filterServiceList = (i) => {
    let arr = serviceList?.filter(
      (item) => serviceData[i].service_id?.id !== item?.id
    );
    setServiceList(arr);
  };

  return (
    <section>
      <div className="bg-white px-3 py-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9 mb-5">
              <h2 className="h2 mt-5 theme-color font-weight-bold text-center">
                Xaphal for Mentor
              </h2>
              <form
                className="form-style mt-5"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                    <div className="position-relative">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          {time > 0 && isActive ? (
                            <Progress type="circle" width={50} percent={time} />
                          ) : (
                            <img
                              src={
                                getValues("image")
                                  ? getValues("image")
                                  : "assets/images/upload-img.png"
                              }
                              alt=""
                            />
                          )}

                          {time > 0 && isActive ? null : (
                            <div className="camera-icon">
                              <i className="fa fa-camera"></i>
                            </div>
                          )}
                        </button>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            if(e.target.files.length > 0) {
                              handleImage(e);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <span className="text-danger">
                      {errors?.image?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                    <label>First Name</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter First Name"
                        {...register(`firstName`)}
                      />
                      <img
                        src="assets/images/user.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors && errors?.firstName?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                    <label>Last Name</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Last Name"
                        {...register(`lastName`)}
                      />
                      <img
                        src="assets/images/user.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors && errors?.lastName?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <label>Gender</label>

                    <div className="Other-radio">
                      <span className="checkbox">
                        <input
                          type="radio"
                          id="Male"
                          name="gender"
                          value="Male"
                          {...register(`gender`)}
                        />
                        <label htmlFor="Male">Male</label>
                      </span>
                      <span className="checkbox">
                        <input
                          type="radio"
                          id="Female"
                          name="gender"
                          value="Female"
                          {...register(`gender`)}
                        />
                        <label htmlFor="Female">Female</label>
                      </span>
                      <span className="checkbox">
                        <input
                          type="radio"
                          id="Other"
                          name="gender"
                          value="Other"
                          {...register(`gender`)}
                        />
                        <label htmlFor="Other">Other</label>
                      </span>
                    </div>
                    <span className="text-danger">
                      {errors && errors?.gender?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <label>Designation</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Designation"
                        {...register(`designation`)}
                      />
                      <img
                        src="assets/images/designation.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors && errors?.designation?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <label>Company Name</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your company name"
                        {...register(`companyName`)}
                      />
                      <img
                        src="assets/images/Companyname.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors && errors?.companyName?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                    <label>Country</label>
                    <div className="position-relative">
                      <Controller
                        render={({
                          field: {onChange, onBlur, value, name, ref},
                          fieldState: {invalid, isTouched, isDirty, error},
                        }) => (
                          <select
                            className="form-control"
                            name="country"
                            onChange={(e) => {
                              // onChange(e);
                              if(e.target.value) {
                                let arr = e.target.value.split(",");
                                let val = arr[0];
                                handleCountry(e.target.name, val);
                                getState(arr[1]);
                              }
                            }}
                          // value={getValues("country")}
                          >
                            <option value="">Select Country</option>
                            <option
                              value={country?.name + "," + country?.isoCode}
                            >
                              {country?.name}
                            </option>
                          </select>
                        )}
                        name="country"
                        control={control}
                        value={getValues("country")}
                        defaultValue={null}
                      />

                      <img
                        src="assets/images/map.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors && errors?.country?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                    <label>State</label>
                    <div className="position-relative">
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                      /> */}
                      <Controller
                        render={({
                          field: {onChange, onBlur, value, name, ref},
                          fieldState: {invalid, isTouched, isDirty, error},
                        }) => (
                          <select
                            className="form-control"
                            name="state"
                            onChange={(e) => {
                              const arr = e.target.value.split(",");
                              const val = arr[0];
                              handleCountry(e.target.name, val);
                              getCity(arr[1]);
                              // setState_isocode(arr[1]);
                            }}
                          >
                            <option value="">Select state</option>

                            {states?.map((item) => {
                              return (
                                <option value={item.name + "," + item.isoCode}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                        name="state"
                        control={control}
                        defaultValue={null}
                        value={getValues("state")}
                      />
                      <img
                        src="assets/images/map.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors && errors?.state?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                    <label>City</label>
                    <div className="position-relative">
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                      /> */}
                      <Controller
                        render={({
                          field: {onChange, onBlur, value, name, ref},
                          fieldState: {invalid, isTouched, isDirty, error},
                        }) => (
                          <select
                            className="form-control"
                            name="city"
                            onChange={(e) => {
                              const val = e.target.value.split(",");
                              handleCountry(e.target.name, val[0]);
                              // setCity_isocode(val[1]);
                            }}
                          >
                            <option value="">Select city</option>

                            {city?.map((item) => {
                              return (
                                <option value={item.name + "," + item.isoCode}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                        name="city"
                        control={control}
                        value={getValues("city")}
                        defaultValue={null}
                      />
                      <img
                        src="assets/images/map.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors.city && errors?.city?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <label>About</label>
                    <div className="position-relative">
                      <textarea
                        className="form-control h-auto"
                        rows="5"
                        {...register(`about`)}
                      ></textarea>
                    </div>
                    <span className="text-danger">
                      {errors.about && errors?.about?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                    <label>Primary Phone Number</label>
                    <div className="position-relative">
                      <Controller
                        render={({
                          field: {onChange, onBlur, value, name, ref},
                          fieldState: {invalid, isTouched, isDirty, error},
                        }) => (
                          <PhoneInput
                            country={"in"}
                            containerClass={"form-control"}
                            inputClass={"inputClass"}
                            onChange={(phone, country, e, formattedValue) => {
                              if(phone.length > 0) {
                                const raw = phone.slice(
                                  country?.dialCode?.length
                                );
                                const phoneNumber = parsePhoneNumber(
                                  formattedValue,
                                  country.countryCode
                                );
                                console.log(phoneNumber?.isValid());
                                if(phoneNumber?.isValid()) {
                                  setValue("primaryNumber", raw);
                                  setValue(
                                    "countryCode",
                                    formattedValue?.split(" ")[0]
                                  );
                                } else {
                                  setValue("primaryNumber", "");
                                  setValue("countryCode", "");
                                }
                              }
                            }}
                          />
                        )}
                        name="primaryNumber"
                        control={control}
                        value={
                          getValues("countryCode") + getValues("primaryNumber")
                        }
                        defaultValue={null}
                      />
                      <img
                        src="assets/images/Phone.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors.primaryNumber && errors?.primaryNumber?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                    <label>Secondary Phone Number</label>
                    <div className="position-relative">
                      <Controller
                        render={({
                          field: {onChange, onBlur, value, name, ref},
                          fieldState: {invalid, isTouched, isDirty, error},
                        }) => (
                          <PhoneInput
                            country={"in"}
                            containerClass={"form-control"}
                            inputClass={"inputClass"}
                            onChange={(phone, country, e, formattedValue) => {
                              if(phone.length > 0) {
                                const raw = phone.slice(
                                  country?.dialCode?.length
                                );
                                const phoneNumber = parsePhoneNumber(
                                  formattedValue,
                                  country.countryCode
                                );
                                console.log(phoneNumber?.isValid());
                                if(phoneNumber?.isValid()) {
                                  setValue("secondaryNumber", raw);
                                  setValue(
                                    "secondaryCountryCode",
                                    formattedValue?.split(" ")[0]
                                  );
                                } else {
                                  setValue("secondaryNumber", "");
                                  setValue("secondaryCountryCode", "");
                                }
                              }
                            }}
                          />
                        )}
                        name="secondaryNumber"
                        control={control}
                        value={
                          getValues("secondaryCountryCode") +
                          getValues("secondaryNumber")
                        }
                        defaultValue={null}
                      />
                      <img
                        src="assets/images/Phone.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors.secondaryNumber &&
                        errors?.secondaryNumber?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                    <label>Primary Email</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        placeholder="Enter Primary Email"
                        {...register(`primaryEmail`)}
                      />
                      <img
                        src="assets/images/email.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors.primaryEmail && errors?.primaryEmail?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                    <label>Secondary Email</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Secondary Email"
                        {...register(`secondaryEmail`)}
                      />
                      <img
                        src="assets/images/email.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors.secondaryEmail && errors?.secondaryEmail?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <label>Experience</label>
                    <div className="position-relative">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Experience"
                        {...register(`experience`)}
                      />

                      <img
                        src="assets/images/user.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors && errors?.experience?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <label>Career Path</label>
                    <div className="position-relative reactSelectContainer">
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Last Name"
                        {...register(`lastName`)}
                      /> */}
                      <Controller
                        render={({
                          field: {onChange, onBlur, value, name, ref},
                          fieldState: {invalid, isTouched, isDirty, error},
                        }) => (
                          <Select
                            className="form-control"
                            onChange={(e) => {
                              onChange(e);
                            }}
                            getOptionLabel={(e) => e.title}
                            getOptionValue={(e) => e.id}
                            options={career_paths ? career_paths : undefined}
                            styles={customStyles}
                            isMulti
                          ></Select>
                        )}
                        name={`career_data`}
                        control={control}
                        defaultValue={null}
                      />
                      <img
                        src="assets/images/user.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors && errors?.career_data?.message}
                    </span>
                  </div>

                  {fields?.map((item, i) => {
                    return (
                      <>
                        {fields?.length > 1 && (
                          <div className="form-group col-sm-12 col-md-12 col-lg-12 counsellerCrossIcon">
                            <span
                              id="remove_2"
                              className="remove"
                              onClick={() => {
                                remove(i);
                              }}
                            >
                              <img src="assets/images/menu_close.png" alt="" />
                            </span>
                          </div>
                        )}
                        <div
                          className="form-group col-sm-12 col-md-6 col-lg-6 mb-4 "
                          key={i}
                        >
                          <label>Services Offered</label>
                          <div className="position-relative reactSelectContainer">
                            {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          {...register(`lastName`)}
                        /> */}
                            <Controller
                              render={({
                                field: {onChange, onBlur, value, name, ref},
                                fieldState: {
                                  invalid,
                                  isTouched,
                                  isDirty,
                                  error,
                                },
                              }) => (
                                <Select
                                  className="form-control"
                                  onChange={(e) => {
                                    console.log(e);
                                    onChange(e);
                                    filterServiceList(i);
                                  }}
                                  getOptionLabel={(e) => e.title}
                                  getOptionValue={(e) => e.id}
                                  options={
                                    serviceList ? serviceList : undefined
                                  }
                                  styles={customStyles}
                                ></Select>
                              )}
                              name={`service_data[${i}].service_id`}
                              value={`service_data[${i}].service_id`}
                              control={control}
                              defaultValue={null}
                            />
                            <img
                              src="assets/images/service_offered.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          <span className="text-danger">
                            {errors?.service_data &&
                              errors?.service_data[i]?.service_id?.message}
                          </span>
                        </div>
                        <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                          <label>Fee</label>
                          <div className="position-relative">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Fee"
                              {...register(`service_data[${i}].fee`)}
                            />

                            <img
                              src="assets/images/fee.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          <span className="text-danger">
                            {errors?.service_data &&
                              errors?.service_data[i]?.fee?.message}
                          </span>
                        </div>
                      </>
                    );
                  })}
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <span
                      className="AddtoList btn"
                      onClick={() => {
                        append({
                          fee: "",
                          service_id: "",
                        });
                      }}
                    >
                      Add More Services
                    </span>
                  </div>

                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                    <button className="btn add-btn">
                      {loading ? <Loader /> : "Sign Up"}
                    </button>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                    <p className="mb-0">
                      <b>Already Member</b>
                    </p>
                  </div>

                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                    <div className="log-singn">
                      <span
                        className="shadow-none get-in-touch d-block w-50 py-2 nav-link border-2 theme-color bg-transparent m-auto"
                        onClick={handleShow}
                      >
                        Sign-in
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <SignIn
          show={show}
          handleShow={() => {
            handleShow();
          }}
          page="other"
        />
      )}
    </section>
  );
}

export default MentorSignup;
