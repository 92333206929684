import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import {
  postFormDataNoToken,
  getDataNoToken,
  putFormData,
  postFormData,
  postRawData,
  getData,
  getDataStringify,
  getDataforUrl,
  patchFormData,
  deleteData,
  getDataAdmin,
} from "../services/httphandler";

const initialState = {
  isLoggedIn: false,
  signInModalShow: false,
  sidebarShow: false,
  mobileUserListShow: true,
  loading: false,
  userId: "",
  userEmail: "",
  userImage: null,
  userFirstname: null,
  userLastname: null,
  privacyPolicy: null,
  tnc: null,
  country: null,
  career_collaboration_list: [],
  career_personas_list: [],
  specilaityList: [],
  careerPath: [],
  skill_list: [],
  specIds: [],
  loggedInUser: "",
  userDetail: null,
  festival_session: null,
  totalQuestions: 0,
  questionList: [],
  certificate_list: [],
  upcomming_session_list: [],
  setShowUpcommingSession: false,
  setShowStudentUpcommingSession: false,
  total_certificate: 0,
  userList: [],
  totalCounseller: 0,
  signUpModalShow: false,
  user_type: "",
  allServiceList: [],
  email: "",
  otherUserDetail: null,
  careerPathDetails: null,
  searchValue: "",
  industry: [],
  otpModalShow: false,
  setFetchQuestion: false,
  otpNotVerifiedFor: "",
  publicQuestionLists: [],
  publicTotalQuestions: 0,
  stepperFormSelected: 1,
  signupFormUserType: "Student",
  requestStatus: localStorage.getItem("requestStatus")
    ? localStorage.getItem("requestStatus")
    : false,
  publicSearch: localStorage.getItem("publicSearch")
    ? localStorage.getItem("publicSearch")
    : "",
  modalName: localStorage.getItem("modalName")
    ? localStorage.getItem("modalName")
    : "",
  otpFor: "email verification",
  otpShow: false,
  forgetPasswordShow: false,
  getAllCertificationsList: [],
  total_inactive_certification: 0,
  total_active_certification: 0,
  total_certification: 0,
  discoverData: [],
  signUpCheckBox: true
};
// user/myActivity
export const signInModal = (store) => store.signInModalShow;
export const searchValueStore = (store) => store.searchValue;
// Authentications
export const signup = createAsyncThunk("signup", async (obj, {dispatch}) => {
  try {
    dispatch(isloading(true));
    const response = await postFormDataNoToken("auth/signup", obj);
    // console.log(response);
    // localStorage.setItem("username", response?.data?.username);
    dispatch(isloading(false));
    return response;
  } catch(error) {
    console.log(error.message);
  }
});
export const checkEmailExist = createAsyncThunk("checkEmailExist", async (obj, {dispatch}) => {
  try {
    dispatch(isloading(true));
    const response = await postFormDataNoToken("auth/checkEmailExist", obj);
    // console.log(response);
    // localStorage.setItem("username", response?.data?.username);
    dispatch(isloading(false));
    return response;
  } catch(error) {
    console.log(error.message);
  }
});

export const completeSignup = createAsyncThunk("CompleteSignup", async (obj, {dispatch}) => {
  try {
    dispatch(isloading(true));
    const response = await postFormDataNoToken("auth/complete-signup", obj);
    // console.log(response);
    // localStorage.setItem("username", response?.data?.username);
    if(response.code === 1) {
      if(response.data?.user || response?.data?.token) {
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem("user", JSON.stringify(response.data.user));
      }

      dispatch(loggedInUserType(response?.data?.user?.user_type));
      const userType = response?.data?.user?.user_type;
      const userId = response?.data?.user?.userId;

      // if(userType === "student") {
      //   dispatch(getUserDetail({}));

      // } else {
      //   dispatch(getOtherUserDetails({user_id: userId, user_type: userType}));
      // }
      // dispatch(getAllCertificationsList({
      //   randomCertification: "random", 
      //   // page:1, 
      //   limit:2,
      //   userId,
      //   visibiltyRole: userType === "counseller"? "counselllor" : userType
      // }))
    }
    dispatch(isloading(false));
    return response;
  } catch(error) {
    console.log(error.message);
  }
});

export const login = createAsyncThunk("login", async (obj, {dispatch}) => {
  try {
    dispatch(isloading(true));
    const response = await postFormDataNoToken("auth/login", obj);

    if(response.code === 1) {
      if(response.data?.user || response?.data?.token) {
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem("user", JSON.stringify(response.data.user));
      }

      dispatch(loggedInUserType(response?.data?.user?.user_type));
      const userType = response?.data?.user?.user_type;
      const userId = response?.data?.user?.userId;

      // if(userType === "student") {
      //   dispatch(getUserDetail({}));
      // } else {
      //   dispatch(getOtherUserDetails({user_id: userId, user_type: userType}));
      // }

      // dispatch(getAllCertificationsList({
      //   randomCertification: "random", 
      //   // page:1, 
      //   limit:2,
      //   userId,
      //   visibiltyRole: userType === "counseller"? "counselllor" : userType
      // }))
    }
    dispatch(isloading(false));

    return response;
  } catch(error) {
    console.log(error.message);
  }
});

export const SendForgetPassword = createAsyncThunk(
  "ForgetPassword",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("auth/forgotPassword", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const ResendOtp = createAsyncThunk(
  "ResendOtp",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("auth/resendOtp", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

//post collaboration request form Data
export const addNewReqForCollaborate = createAsyncThunk(
  "addNewReqForCollaborate",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("message/requestCollaboration", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// GET all getUnreadNotificationCount
export const getAllUnreadNotificationCount = createAsyncThunk(
  "getAllUnreadNotificationCount",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      //console.log(obj);
      const response = await getData("notification/getUnreadNotificationCount", obj);

      dispatch(isloading(false));

      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);
// GET all user chat list whom collaborated
export const getAllUserCollaborateChats = createAsyncThunk(
  "getAllUserCollaborateChats",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      //console.log(obj);
      const response = await getData("message/userChats", obj);

      dispatch(isloading(false));

      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);

// GET all user messages by chat id
export const getAllUserMessageByChatId = createAsyncThunk(
  "getAllUserMessageByChatId",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      //console.log(obj);
      const response = await getData("message/getAllMsgByChatId", obj);

      dispatch(isloading(false));

      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);
// GET all user chat list whom collaborated
export const getAllPendingCollReqList = createAsyncThunk(
  "getAllPendingCollReqList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      //console.log(obj);
      const response = await getData("message/allPendingCollReqList", obj);

      dispatch(isloading(false));

      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);

// GET All Interested and Ignored List
export const getAllReqCollAcceptedReject = createAsyncThunk(
  "getAllFestivalsEvent",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      //console.log(obj);
      const response = await getData("message/getAllReqCollAcceptedReject", obj);

      dispatch(isloading(false));

      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);

//post user message form Data
export const addNewMessage = createAsyncThunk(
  "addNewMessage",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("message/addMessaging", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const sendNewMessageNotification = createAsyncThunk(
  "sendNewMessageNotification",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("notification/sendNewMessageNotification", obj);
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
)

//updateRequestedCollStatus
export const updateRequestedCollStatus = createAsyncThunk(
  "updateRequestedCollStatus",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("message/updateRequestedCollStatus", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

//post festival form Data
export const addNewFestivalDetails = createAsyncThunk(
  "addNewFestivalDetails",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("request/getMeetingDetailsById", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// GET FESTIVALS DATA
export const getAllFestivalsEvent = createAsyncThunk(
  "getAllFestivalsEvent",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      //console.log(obj);
      const response = await getData("request/getMeetingDetailsInfo", obj);

      dispatch(isloading(false));

      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);
//GET FESTIVAL LIST DATA
export const getAllFestivals = createAsyncThunk(
  "getAllFestivals",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      //console.log(obj);
      const response = await getData("request/getMeetingDetailsInfo", obj);

      dispatch(isloading(false));

      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);
export const getAllRunningFestivals = createAsyncThunk(
  "getAllFestivals",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      //console.log(obj);
      const response = await getData("request/getLatestRunningMeeting", obj);

      dispatch(isloading(false));

      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);


//USER COOLEST INFO POPUP
export const AddcoolestProject = createAsyncThunk(
  "AddcoolestProject",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("user/addUserProjectInfo", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

//FOR UPDATE USERSOCIAL POPUP OPTION
export const addUserSocialMedia = createAsyncThunk(
  "addUserSocialMedia",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("user/addUserSocialMedia", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


// USERPROFILE SOCIALICON POPUP OPTION
export const addUserSocialMediaLink = createAsyncThunk(
  "addUserSocialMediaLink",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("user/addUserSocialMedia", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


//FOR UPDATE USERPROFILE POPUP OPTION
export const addUserPersona = createAsyncThunk(
  "addUserPersona",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("user/addUserPersonaInfo", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


//FOR UPDATE USERPROFILE POPUP OPTION
export const addUserCollaborate = createAsyncThunk(
  "addUserCollaborate",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("user/addUserCollaborationInfo", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


export const addCounsellorBackCover = createAsyncThunk(
  "addCounsellorBackCover",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("user/addCounsellorBackCover", obj);
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const addStudentBackCover = createAsyncThunk(
  "addStudentBackCover",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("student/addStudentBackCoverColor", obj);
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

//GET OPTION LIST OF PERSONA
export const getPersonaOptionList = createAsyncThunk(
  "getPersonaOptionList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("admin/getPersonasList", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

//GET OPTION LIST OF collaboration
export const getAllUserCollaborationList = createAsyncThunk(
  "getAllUserCollaborationList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("admin/getCollaborationList", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


export const VerifyOtp = createAsyncThunk(
  "verifyOtp",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormDataNoToken("auth/verifyOtp", obj);
      // console.log('slice verify otp', response);
      if(response.code === 1) {
        //  window.localStorage.setItem("token", response?.data?.token);
        //  window.localStorage.setItem("signuptoken", response?.data?.token);
        //  window.localStorage.setItem("user", JSON.stringify(response.data.user));
        window.localStorage.setItem("username", response?.data?.user?.username);
        if(response.code === 1) {
          dispatch(loggedInUserType(response?.data?.user_type));
          const userType = response?.data?.user_type;
          const userId = response?.data?.userId;

          // if(userType === "student") {
          //   dispatch(getUserDetail({}));

          // } else {
          //   window.localStorage.setItem("user", JSON.stringify(response.data.user));
          //   dispatch(
          //     getOtherUserDetails({user_id: userId, user_type: userType})
          //   );
          // }
        }
      }
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const ResetPassword = createAsyncThunk(
  "ResetPassword",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await putFormData("auth/resetPassword", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// Privacy and terms and conditions
export const getPrivacyAndPolicy = createAsyncThunk(
  "getPrivacyAndPolicy",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("user/getPrivacyAndPolicy", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getTermAndCondition = createAsyncThunk(
  "getTermAndCondition",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("user/getTermAndCondition", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// Add student ambassador

export const AddStudentAmbassador = createAsyncThunk(
  "AddStudentAmbassador",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormDataNoToken(
        "user/addStudentAmbassador",
        obj
      );

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// addTeamRequest
export const addTeamRequest = createAsyncThunk(
  "addTeamRequest",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormDataNoToken("user/addTeamRequest", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// apply college partnership
export const addCollegePartnership = createAsyncThunk(
  "addCollegePartnership",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormDataNoToken(
        "user/addCollegePartnership",
        obj
      );

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// add advertisement
export const addAdvertisement = createAsyncThunk(
  "addAdvertisement",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormDataNoToken("user/addAdvertisement", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// add investor relation
export const addInvestorRelations = createAsyncThunk(
  "addInvestorRelations",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormDataNoToken(
        "user/addInvestorRelations",
        obj
      );

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getAllPersonasList = createAsyncThunk(
  "getAllPersonasList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("admin/getPersonasList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const getAllCollaborationList = createAsyncThunk(
  "getAllCollaborationList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("admin/getCollaborationList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// get country city state

export const getAllCountryList = createAsyncThunk(
  "getAllCountryList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("user/getAllCountryList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// countryIsoCode

export const getAllStateList = createAsyncThunk(
  "getAllStateList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("user/getAllStateList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// state_code

export const getAllCityList = createAsyncThunk(
  "getAllCityList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("user/getAllCityList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// student sign up 1 -- basic info

export const addBasicInfo = createAsyncThunk(
  "addBasicInfo",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await putFormData("student/addBasicInfo", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// get All Added career path and Student Career/specialization Info
export const getAllAddedStudentCareerInfo = createAsyncThunk(
  "getAllAddedStudentCareerInfo",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("student/getAllAddedStudentCareerInfo", obj);
      // console.log("response", response);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// get All Student Career Info
export const getAllStudentCareerInfo = createAsyncThunk(
  "getAllStudentCareerInfo",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postRawData("student/getAllStudentCareerInfo", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// add student degree sign-up 2nd step
export const addStudentDegree = createAsyncThunk(
  "addStudentDegree",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postRawData("student/addStudentDegree", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// add student career infor sign-up 3rd step
export const addStudentCareerInfo = createAsyncThunk(
  "addStudentCareerInfo",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postRawData("student/addStudentCareerInfo", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// add student skills student sign up 4th step
//  {
// 	"skill_data":[{
// 		"skill_id":"",
// 		"skill_name":"test skill",
// 		"level":"3"
// 	}]
// }
export const addStudentSkill = createAsyncThunk(
  "addStudentSkill",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postRawData("student/addStudentSkill", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// speciality list
export const getAllSpecialityList = createAsyncThunk(
  "getAllSpecialityList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("user/getAllSpecialityList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getAllCareerList = createAsyncThunk(
  "getAllCareerList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("user/getAllCareerList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// specialization_id

export const getAllSkillList = createAsyncThunk(
  "getAllSkillList",
  async (obj, {dispatch}) => {
    try {
      // console.log(JSON.stringify(obj));
      // const params = new URLSearchParams(obj);
      dispatch(isloading(true));
      const response = await getDataStringify("user/getAllSkillList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// sign up counseller

export const addCounsellerDetails = createAsyncThunk(
  "addCounsellerDetails",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("user/addCounsellerDetails", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// addMentorDetails
export const addMentorDetails = createAsyncThunk(
  "addMentorDetails",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("user/addMentorDetails", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// add recruiter
export const addRecruiterDetails = createAsyncThunk(
  "addRecruiterDetails",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("user/addRecruiterDetails", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// join early

export const addEarlyJoin = createAsyncThunk(
  "addEarlyJoin",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormDataNoToken("user/addEarlyJoin", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// user detail

export const getUserDetail = createAsyncThunk(
  "getUserDetail",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("user/getUserDetails", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// my activity
export const myActivity = createAsyncThunk(
  "myActivity",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("user/myActivity", obj);
      console.log("complete", response);
      /*
      if(response.code == 1) {
        console.log("liked_question", response.data.liked_question);
        //if(response.data.liked_question)
        let userDetailsArr = response.data.liked_question;
        for(var i = 0;i < userDetailsArr.length;i++) {
          console.log("in_api_res_new", userDetailsArr[i]);
          if(userDetailsArr.userId == null) {
            //if(userDetailsArr.adminId) {
            console.log("work_pro_start", userDetailsArr[i]);
            userDetailsArr[i].userId = userDetailsArr[i].admin_details.id;
            console.log("work_pro_user", userDetailsArr[i]);
            userDetailsArr[i].user_details = {};
            userDetailsArr[i].user_details.id = userDetailsArr[i].admin_details.id;
            userDetailsArr[i].user_details.image = userDetailsArr[i].admin_details.image;
            console.log("work_pro_image", userDetailsArr[i]);

            userDetailsArr[i].user_details.first_name = userDetailsArr[i].admin_details.name;
            userDetailsArr[i].user_details.last_name = userDetailsArr[i].admin_details.name;
            userDetailsArr[i].user_details.work_experience = [{}];
            console.log("work_pro_end", userDetailsArr[i]);
            //}
          }
        }
        response.data.liked_question = userDetailsArr;
        console.log("final_res_1", response);
      }
      */
      console.log("final_res_2", response);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


//POST ADD OPPORTUNITY  1
export const addOpportunity = createAsyncThunk(
  "addOpportunity",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("opportunity/addOpportunity", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

//GET OPPORTUNITY 2 
export const getOpportunity = createAsyncThunk(
  "getOpportunity",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("opportunity/getOpportunity", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


//GET OPPORTUNITY LIST 3
export const getOpportunityList = createAsyncThunk(
  "opportunityList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData(`opportunity/opportunityList`, obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


// ADD FLAG OPPORTUNITY LIST 4
export const addOpportunityFlag = createAsyncThunk(
  "addFlagOnOpportunity",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("opportunity/addFlagOnOpportunity", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// ADD BOOKMARK ON OPPORTUNITY 5
export const addBookMarkOpportunity = createAsyncThunk(
  "addBookmarkOnOpportunity",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("opportunity/addBookmarkOnOpportunity", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


//ADD LIKE ON COMMENT OPPORTUNITY
export const addLikeOnOpportunityComment = createAsyncThunk(
  "addLikeOnOpportunityComment",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("opportunity/addLikeOnComment", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


//ADD FLAG ON COMMENT OPPORTUNITY COMMENT
export const addFlagOnOpportunityComment = createAsyncThunk(
  "addFlagOnOpportunityComment",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("opportunity/addFlagOnComment", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


// ADD COMMENT ON OPPORTUNITY 6
export const addCommentOpportunity = createAsyncThunk(
  "addCommentOnOpportunity",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("opportunity/addCommentOnOpportunity", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);



// ADD LIKE ON OPPORTUNITY 7
export const addlikeOpportunity = createAsyncThunk(
  "addlikeOnOpportunity",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("opportunity/addlikeOnOpportunity", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


// ADD LIKE ON OPPORTUNITY 7
export const removeOpportunity = createAsyncThunk(
  "removeOpportunity",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await deleteData("opportunity/removeOpportunity", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


// ADD LIKE ON OPPORTUNITY 7
export const removeCommentOpportunity = createAsyncThunk(
  "removeCommentOpportunity",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await deleteData("opportunity/removeCommentOpportunity", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);




// question list
export const questionList = createAsyncThunk(
  "questionList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("question/questionList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const questionLists = createAsyncThunk(
  "questionLists",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("question/questionList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// question details questionId
export const getQuestion = createAsyncThunk(
  "getQuestion",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("question/getQuestion", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getQuestionDetails = createAsyncThunk(
  "getQuestionDetails",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataNoToken("question/getQuestionDetails", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const certificateList = createAsyncThunk(
  "certificateList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("student/certificateList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const schoolListReducer = createAsyncThunk(
  "schoolListReducer",
  async (obj, {dispatch}) => {
    try {
      const response = await getData("user/getAllOrganizationList", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const addCertificate = createAsyncThunk(
  "addCertificate",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("student/addCertificate", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// questionId
export const addCommentOnQuestion = createAsyncThunk(
  "addCommentOnQuestion",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("question/addCommentOnQuestion", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// add question
export const addQuestion = createAsyncThunk(
  "addQuestion",
  async (obj, {dispatch}) => {
    // console.log("editquestion",obj);
    try {
      dispatch(isloading(true));
      const response = await postFormData("question/addQuestion", obj);
      console.log('response', response);
      dispatch(isloading(false));
      dispatch(questionList({page: 1, limit: 5}));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// add flag
// questionId
export const addFlag = createAsyncThunk(
  "addFlag",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("question/addFlag", obj);

      dispatch(isloading(false));
      // dispatch(questionList({ page: 1, limit: 10 }));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// add bookmark
// questionId

export const addBookmark = createAsyncThunk(
  "addBookmark",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("question/addBookmark", obj);
      // dispatch(questionList({ page: 1, limit: 10 }));

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// add skill
// skill_id, level
export const addSkill = createAsyncThunk(
  "addSkill",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("student/addSkill", obj);

      dispatch(isloading(false));
      dispatch(getUserDetail({}));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// commentId

export const addLikeOnComment = createAsyncThunk(
  "addLikeOnComment",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("question/addLikeOnComment", obj);

      dispatch(isloading(false));
      dispatch(getUserDetail({}));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const removeComment = createAsyncThunk(
  "removeComment",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await deleteData("question/removeComment", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const removeQuestion = createAsyncThunk(
  "removeQuestion",
  async (obj, {dispatch}) => {
    console.log("removequestion", obj);
    try {
      dispatch(isloading(true));
      const response = await deleteData("question/removeQuestion", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const likeQuestion = createAsyncThunk(
  "likeQuestion",
  async (obj, {dispatch}) => {
    console.log("obj like", obj);
    try {
      const response = await postFormData("question/likeQuestion", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const addFlagOnComment = createAsyncThunk(
  "addFlagOnComment",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("question/addFlagOnComment", obj);

      dispatch(isloading(false));
      dispatch(getUserDetail({}));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getUserDetailsByName = createAsyncThunk(
  "getUserDetailsByName",
  async (obj, {dispatch}) => {
    console.log('getUserDetailsByName', obj);
    try {
      dispatch(isloading(true));
      const response = await getDataforUrl("user/getUserDetailsByName", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

//  user_type: student/counseller/mentor/recruiter, with page limit and search
export const userList = createAsyncThunk(
  "userList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("user/userList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
//  user_id: 26
// user_type
export const getOtherUserDetails = createAsyncThunk(
  "getOtherUserDetails",
  async (obj, {dispatch}) => {

    try {
      dispatch(isloading(true));
      const response = await getData("user/getOtherUserDetails", obj);
      // console.log("ravi");
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// add student career infor sign-up 3rd step
export const addStudentCareerPath = createAsyncThunk(
  "addStudentCareerPath",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postRawData("student/addStudentCareerPath", obj);
      dispatch(isloading(false));
      dispatch(getUserDetail({}));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getAllServiceList = createAsyncThunk(
  "getAllServiceList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("user/getAllServiceList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const getAllUserServiceList = createAsyncThunk(
  "getAllUserServiceList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("user/getAllUserServiceList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getAllServices = createAsyncThunk(
  "getAllServices",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("user/getAllServiceList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

// request/addRequest
// user_id, skill_id

export const addRequest = createAsyncThunk(
  "addRequest",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("request/addRequest", obj.data);
      dispatch(getOtherUserDetails(obj.params));
      console.log("bard");
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const createMeeting = createAsyncThunk(
  "createMeeting",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("request/createMeeting", obj);
      // dispatch(getOtherUserDetails(obj.params));
      console.log("create meeting", response);
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const sessionStartMeetingAlert = createAsyncThunk(
  "sessionStartMeetingAlert",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("notification/addSessionStart", obj);
      // dispatch(getOtherUserDetails(obj.params));
      console.log("start meeting alert", response);
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const startMeeting = createAsyncThunk(
  "joinMeeting",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("request/startMeeting", obj);
      // dispatch(getOtherUserDetails(obj.params));
      console.log("join meeting", response);
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const joinMeeting = createAsyncThunk(
  "joinMeeting",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("request/joinMeeting", obj);
      // dispatch(getOtherUserDetails(obj.params));
      console.log("join meeting", response);
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);



// mentors api

export const addUserServiceInfo = createAsyncThunk(
  "addUserServiceInfo",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("user/addUserServiceInfo", obj.data);
      dispatch(getAllServiceList(obj.params));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const updateUserServiceInfo = createAsyncThunk(
  "updateUserServiceInfo",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("user/updateUserServiceInfo", obj.data);
      // dispatch(getAllServiceList(obj.params));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getDiscoverCertifications = createAsyncThunk(
  "getDiscoverCertificationsList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      //console.log(obj);
      const response = await getData("user/fetchCertificationByUserRole", obj);
      console.log("discover_data_slice", response?.data?.certifications);
      dispatch(isloading(false));
      //state.discoverData = response?.data?.certifications;
      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);
export const getAllCertificationsList = createAsyncThunk(
  "getAllCertificationsList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      //console.log(obj);
      const response = await getData("user/fetchAllCertificationByUserRole", obj);

      dispatch(isloading(false));

      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);

// certification module apis start 
export const addAndUpdateCertificationsUserLog = createAsyncThunk(
  "addAndUpdateCertificationsUserLog",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));

      const response = await postFormData("user/addAndUpdateCertificationsUserLog", obj);
      dispatch(isloading(false));

      return response;
    } catch(error) {
      //console.log(error.message);
    }
  }
);

export const requestList = createAsyncThunk(
  "requestList",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("request/requestList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// request_id
export const requestDetails = createAsyncThunk(
  "requestDetails",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("request/requestDetails", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// request_id, meeting_link, date, time
export const addRequestDetails = createAsyncThunk(
  "addRequestDetails",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("request/addRequestDetails", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// request_id
export const rejectRequest = createAsyncThunk(
  "rejectRequest",
  async (obj, {dispatch}) => {
    try {
      const response = await patchFormData("request/rejectRequest", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// request_id, recorded_video_link
export const completeRequest = createAsyncThunk(
  "completeRequest",
  async (obj, {dispatch}) => {
    try {
      const response = await patchFormData("request/completeRequest", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const shareQuestion = createAsyncThunk(
  "shareQuestion",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("question/shareQuestion", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getCareerDetails = createAsyncThunk(
  "getCareerDetails",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataAdmin("admin/getCareerDetails", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const addRequestTimeSlots = createAsyncThunk(
  "addRequestTimeSlots",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("request/addRequestTimeSlots", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// request_id, time_slot_id

export const acceptTimeSlot = createAsyncThunk(
  "acceptTimeSlot",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("request/acceptTimeSlot", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const removeUserServiceInfo = createAsyncThunk(
  "removeUserServiceInfo",
  async (obj, {dispatch}) => {
    try {
      const response = await deleteData("user/removeUserServiceInfo", obj.data);
      dispatch(getAllServiceList(obj.params));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const removeCareerPathAdded = createAsyncThunk(
  "removeUserServiceInfo",
  async (obj, {dispatch}) => {
    try {
      // console.log("ooooooooo", obj);
      const response = await deleteData("student/removeCareerPathAdded", obj);
      dispatch(getAllServiceList(obj.params));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async (obj, {dispatch}) => {
    try {
      const response = await putFormData("auth/changePassword", obj);
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
// page and limit
export const answeredQuestion = createAsyncThunk(
  "answeredQuestion",
  async (obj, {dispatch}) => {
    try {
      const response = await getData("user/answeredQuestion", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const savedQuestion = createAsyncThunk(
  "savedQuestion",
  async (obj, {dispatch}) => {
    try {
      const response = await getData("user/savedQuestion", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const askedQuestion = createAsyncThunk(
  "askedQuestion",
  async (obj, {dispatch}) => {
    try {
      const response = await getData("user/askedQuestion", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const deletecertificate = createAsyncThunk(
  "deletecertificate",
  async (obj, {dispatch}) => {
    try {
      const response = await deleteData("student/deletecertificate", obj);
      dispatch(certificateList({page: 1, limit: 10, status: "0"}));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getCertificateDetails = createAsyncThunk(
  "getCertificateDetails",
  async (obj, {dispatch}) => {
    try {
      const response = await getDataforUrl(
        "student/getCertificateDetails",
        obj.id
      );

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const getIndustry = createAsyncThunk(
  "getIndustry",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("user/getAllIndustryList", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const addStudentWorkExperience = createAsyncThunk(
  "addStudentWorkExperience",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postRawData(
        "student/addStudentWorkExperience",
        obj
      );

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const AddRating = createAsyncThunk(
  "addRating",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("student/addRating", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


export const createPayment = createAsyncThunk(
  "createPayment",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData("request/generateCashFreeToken", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const SavePayment = createAsyncThunk(
  "savePayment",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("request/savePaymentDetails", obj);

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const AddWalletAmount = createAsyncThunk(
  "addWalletAmount",
  async (obj, {dispatch}) => {
    try {
      const response = await postFormData("user/addWalletAmount", obj);
      if(response?.code === 1) {
        dispatch(getUserDetail());
        console.log('inside slice add walletamount');
      }

      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);

export const FetchOrderDetails = createAsyncThunk(
  "orderDetails",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getDataforUrl("request/getOrderDetails", obj);
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const requestToWithdrawWalletAmount = createAsyncThunk(
  "requestToWithdrawWalletAmount",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await postFormData(
        "user/requestToWithdrawWalletAmount",
        obj
      );
      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


//NOTIFICATION-ALL-DETAILS
//export const getNotificationDetails = createAsyncThunk(
//  "getnotificationDetail",
//  async (obj, {dispatch}) => {
//    try {
//      dispatch(isloading(true));
//      const response = await getDataNoToken("notification/getnotificationDetail", obj);

//      dispatch(isloading(false));
//      return response;
//    } catch(error) {
//      console.log(error.message);
//    }
//  }
//);

export const getNotificationDetails = createAsyncThunk(
  "getnotificationDetail",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("notification/getnotificationDetail", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);
export const markAllNotificationAsRead = createAsyncThunk(
  "markAllNotificationAsRead",
  async (obj, {dispatch}) => {
    try {
      dispatch(isloading(true));
      const response = await getData("notification/markAllAsReadNotification", obj);

      dispatch(isloading(false));
      return response;
    } catch(error) {
      console.log(error.message);
    }
  }
);


export const Slice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    festival_session: (state, action) => {
      state.festival_session = action?.payload;
    },
    setSearchInStore: (state, action) => {
      state.searchValue = action?.payload;
    },
    isloading: (state, action) => {
      state.loading = action.payload;
    },
    toggleSignInModal: (state, action) => {
      state.signInModalShow = action.payload;
    },
    toggleSidebar: (state, action) => {
      state.sidebarShow = action.payload;
    },
    updateMobileUserListShow: (state, action) => {
      state.mobileUserListShow = action.payload;
    },
    storeSpecIds: (state, action) => {
      state.specIds = action.payload;
    },
    loggedInUserType: (state, action) => {
      state.loggedInUser = action.payload;
    },
    toggleSignUpModal: (state, action) => {
      state.signUpModalShow = action.payload.show;
      state.user_type = action.payload.user_type;
    },
    clearDetail: (state, action) => {
      if(action.payload?.user_type === "student") {
        state.userDetail = null;
      } else {
        state.otherUserDetail = null;
      }
    },
    otpNotVerifiedForReducer: (state, action) => {
      state.otpNotVerifiedFor = action.payload;
    },

    otpForReducer: (state, action) => {
      state.otpFor = action.payload;
    },
    setPublicSearch: (state, action) => {
      state.publicSearch = action.payload;
    },
    setModalName: (state, action) => {
      state.modalName = action.payload;
    },
    setOtpShow: (state, action) => {
      state.otpShow = action.payload;
    },
    setSignUpCheckBoxInput: (state, action) => {
      state.signUpCheckBox = action.payload;
    },
    setForgetPasswordShow: (state, action) => {
      state.forgetPasswordShow = action.payload;
    },
    changeRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
    changeIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    addFestivalSession: (state, action) => {
      state.festival_session = action.payload;
    },
    upcomming_session_list: (state, action) => {
      state.upcomming_session_list = action.payload;
    },
    setShowUpcommingSession: (state, action) => {
      state.setShowUpcommingSession = action.payload;
    },
    setShowStudentUpcommingSession: (state, action) => {
      state.setShowStudentUpcommingSession = action.payload;
    },
    setFetchQuestion: (state, action) => {
      state.setFetchQuestion = action.payload;
    },
    stepperFormSelected: (state, action) => {
      state.stepperFormSelected = action.payload;
    },
    signupFormUserType: (state, action) => {
      state.signupFormUserType = action.payload;
    },

  },
  extraReducers: {
    [SendForgetPassword.fulfilled]: (state, action) => {
      let id = jwtDecode(action?.payload?.data?.token);
      state.userId = id?.userId;
      state.userEmail = action?.meta?.arg?.email;
    },
    [VerifyOtp.fulfilled]: (state, action) => {
      localStorage.setItem("token", action.payload?.data?.token);
    },
    [getPrivacyAndPolicy.fulfilled]: (state, action) => {
      state.privacyPolicy = action?.payload?.data?.privacy_and_policy;
    },
    [getTermAndCondition.fulfilled]: (state, action) => {
      state.tnc = action?.payload?.data?.term_and_condition;
    },
    [getAllCountryList.fulfilled]: (state, action) => {
      // state.tnc = action?.payload?.data?.term_and_condition;
      state.country = action?.payload?.data?.country_list;
    },
    [getAllPersonasList.fulfilled]: (state, action) => {
      state.career_personas_list = action?.payload?.data?.career_personas_list;
    },
    [getAllCollaborationList.fulfilled]: (state, action) => {
      state.career_collaboration_list = action?.payload?.data?.career_collaboration_list;
    },
    [signup.fulfilled]: (state, action) => {
      // console.log('signup action', action?.payload?.data);
      // localStorage.setItem("token", action?.payload?.data?.token);
      state.isLoggedIn = true;
      state.email = action?.meta?.arg?.email;
      state.userId = action?.payload?.data?.userId;
    },
    [login.fulfilled]: (state, action) => {
      // (async () => {
      //    localStorage.setItem("token", action?.payload?.data?.token);
      // })();
      state.isLoggedIn = true;
      if(action?.payload?.data?.user_type !== "student") {
        state.userId = action?.payload?.data?.userId;
      } else {
        state.userId = action?.payload?.data?.user?.id;
      }
    },
    [getAllSpecialityList.fulfilled]: (state, action) => {
      state.specilaityList = action?.payload?.data?.speciality_list;
    },
    [getAllCareerList.fulfilled]: (state, action) => {
      state.careerPath = action?.payload?.data?.career_list;
    },
    [getAllSkillList.fulfilled]: (state, action) => {
      state.skill_list = action?.payload?.data?.skill_list;
    },
    [getUserDetail.fulfilled]: (state, action) => {
      state.userDetail = action?.payload?.data?.user;
      state.userImage = action?.payload?.data?.user?.image;
      state.userFirstname = action?.payload?.data?.user?.first_name;
      state.userLastname = action?.payload?.data?.user?.last_name;
    },
    [questionList.fulfilled]: (state, action) => {
      state.questionLists = action?.payload?.data?.question_list;
      state.totalQuestions = action?.payload?.data?.total_question;
    },
    [questionLists.fulfilled]: (state, action) => {
      state.publicQuestionLists = action?.payload?.data?.question_list;
      state.publicTotalQuestions = action?.payload?.data?.total_question;
    },
    [certificateList.fulfilled]: (state, action) => {
      state.certificate_list = action?.payload?.data?.question_list;
      state.total_certificate = action?.payload?.data?.total_question;
    },

    [getAllCertificationsList.fulfilled]: (state, action) => {
      //console.log(action);
      state.getAllCertificationsList = action?.payload?.data?.certifications;
      state.total_certification = action?.payload?.data?.certifications?.length;
      state.total_active_certification = action?.payload?.data?.total_active_certification;
      state.total_inactive_certification =
        action?.payload?.data?.total_inactive_certification;
    },
    // [userList.fulfilled]: (state, action) => {
    //   state.userList = [
    //     ...state.userList,
    //     ...action?.payload?.data?.user_list,
    //   ].filter((object, index, array) => {
    //     return array.findIndex((item) => item?.id === object?.id) === index;
    //   });
    // },

    [getAllServiceList.fulfilled]: (state, action) => {
      state.allServiceList = action?.payload?.data?.service_list;
    },
    [getAllServices.fulfilled]: (state, action) => {
      if(action?.meta?.arg?.user_type === "counseller") {
        state.allServiceList = action?.payload?.data?.service_list;
      } else {
        state.mentorServiceList = action?.payload?.data?.service_list;
      }
    },
    [getOtherUserDetails.fulfilled]: (state, action) => {
      state.otherUserDetail = action?.payload?.data;
      state.otherDetail = action?.payload?.data?.user;
    },
    [getCareerDetails.fulfilled]: (state, action) => {
      state.careerPathDetails = action?.payload?.data?.career_details;
    },
    [myActivity.fulfilled]: (state, action) => {
      state.myActivity = action?.payload?.data;
    },
    [savedQuestion.fulfilled]: (state, action) => {
      state.savedQuestionList = action?.payload?.data?.bookmarked_question;
    },
    [askedQuestion.fulfilled]: (state, action) => {
      state.askedQuestionList = action?.payload?.data?.asked_question_list;
    },
    [answeredQuestion.fulfilled]: (state, action) => {
      state.answeredQuestionList = action?.payload?.data?.anwered_question;
    },
    [getIndustry.fulfilled]: (state, action) => {
      // console.log(action);
      state.industry = action?.payload?.data?.industry_list;
    },
    [getDiscoverCertifications.fulfilled]: (state, action) => {
      state.discoverData = action?.payload?.data?.certifications;
      console.log("discover_data_state", action?.payload?.data?.certifications);
    }
  },
});

export const {
  isloading,
  otpForReducer,
  toggleSignInModal,
  toggleSidebar,
  updateMobileUserListShow,
  storeSpecIds,
  loggedInUserType,
  toggleSignUpModal,
  clearDetail,
  setSearchInStore,
  otpNotVerifiedForReducer,
  setPublicSearch,
  setModalName,
  setOtpShow,
  setSignUpCheckBoxInput,
  setForgetPasswordShow,
  changeRequestStatus,
  changeIsLoggedIn,
  upcomming_session_list,
  setShowUpcommingSession,
  setFetchQuestion,
  setShowStudentUpcommingSession,
  stepperFormSelected,
  signupFormUserType,
  festival_session,
  addFestivalSession
} = Slice.actions;

export default Slice.reducer;
