import Footer from "../../component/Footer";
import Navbar from "../../component/Navbar";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AskQuestion from "../../component/modal/AskQuestion";
import {
  questionList,
  questionLists,
  searchValueStore,
  setSearchInStore,
} from "../slice";
import Loader from "./../../component/Loader";
import QuestionCard from "../../component/QuestionCard/QuestionCard";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router";
const limit = 10;

function PublicQuestion() {
  // const { search } = useParams();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("keyword");
  console.log(name);
  const [askQuestionShow, setAskQuestionShow] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const [question_type, setQuestion_type] = useState("2");
  const [list, setList] = useState([]);
  // console.log(location, "Question list");
  const loading = useSelector((store) => store.loading);
  // const search = useSelector(searchValueStore);
  const observer = useRef();
  const [noData, setNoData] = useState(false);

  const lastCardRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      const options = {
        root: null,
        rootMargin: "20px",
        threshold: 0.8,
      };
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !noData) {
          setActivePage((prevNum) => prevNum + 1);
        }
      }, options);
      if (node) observer.current.observe(node);
    },
    [loading, noData]
  );

  useEffect(() => {
    return () => {
      dispatch(setSearchInStore(""));
    };
  }, [dispatch]);

  const handlePage = (page) => {
    getList();
  };
  const handleAskQuestionShow = () => {
    setAskQuestionShow(!askQuestionShow);
  };
  const getList = useCallback(() => {
    dispatch(
      questionLists({
        page: activePage,
        limit: limit,
        question_type: question_type,
        search: name,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        console.log(res, "Quretions");
        const arr = [...list, ...res?.payload?.data?.question_list];
        // const result = arr.filter((i, index) =>
        //   arr.findIndex((f) => f.id === i.id)
        // );
        console.log(arr);
        setList(arr);
        if (res?.payload?.data?.question_list?.length === 0) {
          setNoData(true);
        }
      }
    });
  }, [activePage, dispatch, question_type, name]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <div id="afterLoginScreen">
      <div className="container my-3">
        <div className="row align-items-center mt-4">
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <h5 className="theme-color">Search results for "{name}"</h5>
          </div>

          <div className="col-sm-12">
            {list?.length > 0 ? (
              list?.map((item, i) => {
                if (i === list.length - 1) {
                  return (
                    <QuestionCard
                      item={item}
                      refresh={getList}
                      key={i}
                      ref={lastCardRef}
                    />
                  );
                } else {
                  return <QuestionCard item={item} refresh={getList} key={i} />;
                }
              })
            ) : (
              <div>No data found.</div>
            )}
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12">
            {loading ? <Loader /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicQuestion;
