import React, {useEffect, useState} from "react";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
// import { useHistory } from 'react-router-dom';
// import { Helmet } from 'react-helmet';

function NotFound() {
  const [error404, setError404] = useState("404.")
  const navigate = useNavigate();
  // const history = useHistory();
  const {location, pathname} = useLocation();
  console.log("location456", location);
  console.log("pathname", pathname);
  function handleClick() {
    // history.push(`/${pathname}`, { status: 200 });
    const newState = {status: 404};
    const newUrl = `/${pathname}`;
    // navigate(newUrl, { state: newState });
    navigate(`${window.location.href}`, {state: newState});
  }

  // useEffect(() => {
  //   handleClick()
  //  }, []);
  //
  // useEffect(() => {
  //  //console.log("res")
  //  axios.get(`${process.env.REACT_APP_BACKEND_URL}/404`).then(res => {
  //    console.log(res, "res")
  //  }).catch(errr => {
  //    console.log(errr, "res")
  //  });

  // }, []);
  console.log("window location456", window.location.pathname);
  console.log("window ", window.location);
  return (
    <div>
      {/* <Helmet>
        <title>Page Title</title>
        <meta name="description" content="Page description" />
        <link rel="canonical" href="https://example.com/page" />
      </Helmet> */}
      {/* <Helmet>
        <meta name="prerender-status-code" content="501" /> 
      </Helmet> */}
      <section>
        <div className="body-bg landing-page">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 m-5">
                <div className="page_not_found_style">
                   <h1>{error404} <br/> Page Not Found.</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NotFound;
