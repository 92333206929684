import React from 'react';
import xaphalLogo from "../assets/svg-images/latest-xaphal-logo.svg"

const LatestHomePage = () => {
	window.localStorage.setItem("token", null);
	window.localStorage.setItem("user", null);
	return (
		<div className='latest-homepage-div'>
			<div className='homepage-container'>
				<div className='homepage-content'>
					<div className='logo'>
						<img src={xaphalLogo} alt='logo' />
					</div>
					<div className='logo-bottom-text'>Saphalta with an X factor</div>
					<div className='evolving_to_serve_you_better mt-5'>Evolving to Serve You Better!</div>
					<div className='homepage_desc mt-4 '>The world changes, and so do we. Xaphal is transforming to redefine your experience with us. We're momentarily stepping back to leap forward and present you with a ground-breaking experience!</div>
					<div className='homepage_thank_text mt-5'>Thank you for your patience and continued support. We can't wait to welcome you back soon!</div>
					<div className=' mt-5'>
						<a href="mailto:help@xaphal.com" target="_blank" className='homepage_reach_us' rel="noopener noreferrer">Reach us here</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LatestHomePage