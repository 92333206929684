import React from "react";
import ReactDOM from "react-dom";
import {createRoot} from 'react-dom/client';
import {Provider} from "react-redux";
import App from "./App";
import store from "./app/store";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {GoogleOAuthProvider} from '@react-oauth/google';
// Create a client
// console.log('index', process.env.REACT_APP_BACKEND_URL);
// process.env.REACT_APP_NODE_ENV === 'local' || item?.like_list ? item?.like_list?.length : item?.total_like
if(process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === "development") {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}
const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
window.localStorage.setItem("token", null);
window.localStorage.setItem("user", null);
root.render(
  //<React.StrictMode>
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ToastContainer autoClose={3000} />
        <App />
      </Provider>
    </QueryClientProvider>
  </GoogleOAuthProvider>
  //</React.StrictMode>
);


// ReactDOM.render(
//   <React.StrictMode>
//     <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
//       <QueryClientProvider client={queryClient}>
//         <Provider store={store}>
//           <ToastContainer autoClose={3000} />
//           <App />
//         </Provider>
//       </QueryClientProvider>
//     </GoogleOAuthProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
