import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import {addTeamRequest} from "../slice";
import {useDispatch, useSelector} from "react-redux";
import {uploadFile} from "react-s3";
// import S3FileUpload from "react-s3";
import S3 from "react-aws-s3";
import {Progress} from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import {toast} from "react-toastify";
import Loader from "../../component/Loader";
import parsePhoneNumber from "libphonenumber-js";
import NewNavbar from "../../component/NewNavbar";
import Footer from "../../component/Footer";
window.Buffer = window.Buffer || require("buffer").Buffer;
const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};
function ApplyTeam({color_code, search_show, footerLinkPage}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const loading = useSelector((state) => state.loading);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval;
    if(isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const ReactS3Client = new S3(config);
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Kindly enter a valid email.")
      .required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    message: yup.string().required("Required"),
    picture: yup.string().required("Required"),
    mobile: yup
      .number()
      .positive()
      .integer()
      .typeError("Kindly enter a valid phone number.")
      .required("Required"),
    countryCode: yup
      .number()
      .positive()
      .integer()
      .typeError("Kindly enter a valid phone number.")
      .required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
    getValues,
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
    shouldFocusError: "true",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    console.log(data);
    // navigate("/sign-up/3");
    dispatch(
      addTeamRequest({
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        mobile: data.mobile,
        countryCode: data.countryCode,
        message: data.message,
        file: getValues("picture"),
      })
    ).then((res) => {
      console.log(res);
      if(res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        navigate("/", {replace: true});
      } else {
        if(res?.payload?.message == "email is already taken!" || res?.payload?.message == "mobile is already taken!")
          toast.error("You've already filled a form");
        else
          toast.error(res?.payload?.message);
      }
    });
  };
  const handleFile = (e) => {
    const {files} = e.target;
    setIsActive(true);
    uploadFile(files[0], config)
      .then((data) => {
        console.log("data", data);
        setValue("picture", data.location);
        setTime(100);
        setIsActive(true);
        setTimeout(() => {
          setTime(0);
          setIsActive(false);
        }, 2000);
      })
      .catch((err) => console.error(err));
  };

  console.log(errors);
  // console.log("color_code",color_code);


  return (
    <section>
      <div id="beforeLoginScreen">
        <NewNavbar
        // search_show={search_show}
        // footerLinkPage={footerLinkPage}
        // style={{backgroundColor:"#141414!important"}}
        />
        <div className="bg-white px-3 py-5">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-9 mb-5">
                <h2 className="h2 mt-5 theme-color font-weight-bold text-center">
                  Join our Team{" "}
                </h2>
                <p className="text-center font-weight-bold">
                  Send us your CV. Our personnel requirements are constantly
                  changing and our next project might be perfect for you!
                </p>
                <form
                  className="form-style mt-5 userNotAuthenticateForm"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label>First Name</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control input-text"
                          placeholder="Enter First Name"
                          {...register(`firstName`)}
                        />
                        <img
                          src="assets/images/user.png"
                          className="input-img input-placeholder-image"
                          alt=" "
                        />
                      </div>
                      <span className="text-danger">
                        {errors && errors?.firstName?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label>Last Name</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control input-text"
                          placeholder="Enter Last Name"
                          {...register(`lastName`)}
                        />
                        <img
                          src="assets/images/user.png"
                          className="input-img input-placeholder-image"
                          alt=" "
                        />
                      </div>
                      <span className="text-danger">
                        {errors && errors?.lastName?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label>Email</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control input-text"
                          placeholder="Enter Email Address"
                          {...register(`email`)}
                        />
                        <img
                          src="assets/images/email.png"
                          className="input-img input-placeholder-image"
                          alt=" "
                        />
                      </div>
                      <span className="text-danger">
                        {errors && errors?.email?.message}
                      </span>
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label>Phone</label>
                      <div className="position-relative">
                        <Controller
                          render={({
                            field: {onChange, onBlur, value, name, ref},
                            fieldState: {invalid, isTouched, isDirty, error},
                          }) => (
                            <PhoneInput
                              country={"in"}
                              // containerClass={"form-control"}
                              inputClass={"inputClass"}
                              // style={{paddingBottom:"0px"}}
                              onChange={(phone, country, e, formattedValue) => {
                                if(phone.length > 0) {
                                  const raw = phone.slice(
                                    country?.dialCode?.length
                                  );
                                  const phoneNumber = parsePhoneNumber(
                                    formattedValue,
                                    country.countryCode
                                  );

                                  if(phoneNumber?.isValid()) {
                                    setValue("mobile", raw);
                                    setValue(
                                      "countryCode",
                                      formattedValue?.split(" ")[0]
                                    );
                                  } else {
                                    setValue("mobile", "");
                                    setValue("countryCode", "");
                                  }
                                }
                              }}
                            />
                          )}
                          name="mobile"
                          control={control}
                          value={getValues("countryCode") + getValues("mobile")}
                          defaultValue={null}
                        />
                        <img
                          src="assets/images/Phone.png"
                          className="input-img input-placeholder-mobile"
                          alt=" "
                        />
                      </div>
                      <div className="mobile-phone-error">
                        <span className="text-danger">
                          {errors && errors?.mobile?.message}
                        </span>
                      </div>
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 mt-4">
                      <label>Your Message</label>
                      <div className="position-relative">
                        <textarea
                          className="form-control h-auto input-textarea"
                          rows="5"
                          //placeholder="Message"
                          {...register(`message`)}
                        ></textarea>
                        <img
                          src="assets/images/message.png"
                          className="input-img input-placeholder-image"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors && errors?.message?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-3">
                      <label>Attachment</label>
                      {/*<button className="attachment-btn">Choose file</button>*/}
                      <div className="position-relative">
                        <div className="file-input">
                          <Controller
                            control={control}
                            name="picture"
                            render={({field: {onChange}}) => (
                              <input
                                type="file"
                                id="file-input"
                                className="file-input__input"
                                onChange={(e) => {
                                  //console.log(e);
                                  // onChange(e);
                                  // console.log(e.target.files);
                                  //handleFile(e.target.files[0]);
                                  if(e.target.files.length > 0) {
                                    handleFile(e);
                                  }
                                }}
                              />
                            )}
                          />
                          <label
                            className="file-input__label"
                            htmlFor="file-input"
                          >
                            {time > 0 && isActive ? (
                              <Progress
                                type="circle"
                                width={30}
                                style={{
                                  textAlign: "center",
                                }}
                                percent={time}
                              />
                            ) : (
                              <span>Choose file </span>
                            )}
                          </label>
                        </div>

                        <span className="text-danger">
                          {errors && errors?.picture?.message}
                        </span>
                      </div>
                    </div>

                    {!isActive && getValues("picture") && (
                      <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                        <label>File Uploaded</label>
                        <div className="position-relative text-center">
                          <img
                            src={getValues("picture")}
                            alt=""
                            className="inputImage radio-img"
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label className="d-flex">
                        <input type="checkbox" className="form-control checkbox-input" />
                        <span className="term-and-condition-text" >I have read and agree to the <Link to="/terms-and-condition" className="terms-and-privacy-policy">Terms and Conditions</Link> & <Link to="/privacy-policy" className="terms-and-privacy-policy"  >Privacy Policy</Link>. I also consent to receive marketing materials, and updates from Xaphal.</span>
                      </label>
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                      <button className="btn add-btn submit-btn">
                        {" "}
                        {loading ? <Loader /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
}

export default ApplyTeam;
