import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
  AddRating,
  addRequest,
  clearDetail,
  createPayment,
  getOtherUserDetails,
  getUserDetail,
} from "../slice";
import Loader from "./../../component/Loader";
import {useFormik} from "formik";
import * as Yup from "yup";
const Rating = require("react-rating").default;
function CounsellerDetails() {
  const {id, skill, userType} = useParams();
  console.log("ii", id, skill, userType);
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((store) => store.loading);
  const data = useSelector((store) => store.otherUserDetail);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((store) => store.userDetail);

  console.log(data, "datatattttttt")
  useEffect(() => {
    dispatch(getUserDetail({}));
  }, [dispatch]);

  const getDetails = useCallback(() => {
    dispatch(
      getOtherUserDetails({
        user_id: id,
        user_type: userType === "counsellor" ? "counseller" : userType,
        service_id: skill,
      })
    );
  }, [dispatch, id, skill, userType]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);
  useEffect(() => {
    return () => {
      dispatch(clearDetail({user_type: userType}));
    };
  }, [dispatch, userType]);

  const sendRequest = () => {
    setIsLoading(true);
    const requestData = {
      data: {
        user_id: id,
        service_id: skill,
        price: data?.fee,
        wallet_amount: user?.wallet_amount >= data?.fee ? data?.fee : "",
      },
      params: {
        user_id: id,
        user_type: userType === "counsellor" ? "counseller" : userType,
        service_id: skill,
      },
    };

    // console.log("wallet", user?.wallet_amount, "and ", data?.fee);
    if(user?.wallet_amount >= data?.fee) {

      dispatch(addRequest(requestData)).then((res) => {
        if(res?.payload?.code === 1) {
          setIsLoading(false);
          dispatch(getUserDetail({})).then((resp) => {
            // console.log(resp);
          });
          dispatch(getOtherUserDetails({
            user_id: id,
            user_type: userType === "counsellor" ? "counseller" : userType,
            service_id: skill,
          })).then((response) => {
            console.log(response);
          });

          toast.success(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      // dispatch(
      //   createPayment({
      //     orderAmount: data?.fee,
      //     orderCurrency: "INR",
      //     orderNote: "",
      //   })
      // ).then((result) => {
      //   console.log(result);
      //   if (result?.payload?.code === 1) {
      //     localStorage.setItem("processing-payment", 1);
      //     localStorage.setItem(
      //       "requestData",
      //       JSON.stringify({
      //         requestData,
      //         payment_for: "other",
      //       })
      //     );
      //     window.location.href = result?.payload?.data?.data?.payment_link;
      //   }
      // });
      setIsLoading(false);

      toast.error("You have insufficient wallet balance.");
    }
  };

  const formik = useFormik({
    initialValues: {
      rating: 0,
      review: "",
    },
    validationSchema: Yup.object({
      rating: Yup.string().required(),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(
        AddRating({
          rating: values.rating,
          review: values.review,
          user_id: id,
        })
      ).then((res) => {
        if(res?.payload?.code === 1) {
          getDetails();
          toast.success(res.payload?.message);
        } else {
          toast.error(res.payload?.message);
        }
      });
    },
  });

  if(!data) {
    return <Loader />;
  } else {
    console.log('dataa', data);
    return (
      <div className="row align-items-center p-3">
        <div className="col-sm-12 col-md-12 col-lg-12 py-4">
          <h5>
            About {data?.user?.first_name} {data?.user?.last_name}
          </h5>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="bg-white rounded px-2 pb-3 text-capitalize">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 my-3">
                <div className="d-flex align-items-center gap-3">
                  <div>
                    {
                      data?.user?.image?.includes("http") ?
                        <img src={data?.user?.image} className="mentor-img mt-1" alt="" />
                        : <span className="profile-counseller_de-letters">{data?.user?.first_name?.charAt(0).toUpperCase()}{data?.user?.last_name?.charAt(0).toUpperCase()}</span>
                    }
                    {/* <img
                      src={
                        data?.user?.image?.includes("http")
                          ? data?.user?.image
                          : "assets/images/profile1.png"
                      }
                      className="mentor-img mt-2"
                      alt=""
                    /> */}
                  </div>
                  <span>
                    <h6
                      className="mb-0 cursor-pointer"
                      onClick={() => {
                        if(data?.user?.username) {
                          navigate(`/${data?.user?.username}`);
                        } else {
                          toast.error("Page not available.");
                        }
                      }}
                    >
                      {data?.user?.first_name} {data?.user?.last_name}
                    </h6>
                    <small
                      style={{
                        textTransform: "none",
                      }}
                      className="mb-0"
                    >
                      {data?.user?.email}
                    </small>{" "}
                    <p className="mb-0 rating-icons">
                      {/* {data?.user?.rating >= 1 ? (
                        <i className="fa fa-star"></i>
                      ) : (
                        <i className="fa fa-star-o"></i>
                      )}{" "}
                      {data?.user?.rating >= 2 ? (
                        <i className="fa fa-star"></i>
                      ) : (
                        <i className="fa fa-star-o"></i>
                      )}{" "}
                      {data?.user?.rating >= 3 ? (
                        <i className="fa fa-star"></i>
                      ) : (
                        <i className="fa fa-star-o"></i>
                      )}{" "}
                      {data?.user?.rating >= 4 ? (
                        <i className="fa fa-star"></i>
                      ) : (
                        <i className="fa fa-star-o"></i>
                      )}{" "}
                      {data?.user?.rating >= 5 ? (
                        <i className="fa fa-star"></i>
                      ) : (
                        <i className="fa fa-star-o"></i>
                      )} */}
                      <Rating
                        initialRating={data?.user?.rating}
                        emptySymbol="fa fa-star-o"
                        fullSymbol={"fa fa-star"}
                        fractions={2}
                        readonly
                      />
                      <span className="text-dark font-weight-bold mx-1">
                        {data?.user?.total_rating > 0
                          ? data?.user?.total_rating
                          : ""}
                      </span>
                    </p>
                  </span>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 text-end">
                <p className="mb-2">
                  <small>
                    {data?.total_session === 1
                      ? `${data?.total_session} session`
                      : data?.total_session > 1
                        ? `${data?.total_session} sessions`
                        : ""}
                  </small>
                </p>
                <button
                  className="btn rounded-sm mb-0 text-center btn-success"
                  onClick={() => {
                    sendRequest();
                  }}
                  style={{
                    backgroundColor: data?.isPendingRequest > 0 ? "grey" : "",
                    border: "0px",
                  }}
                  disabled={data?.isPendingRequest}
                >
                  {isLoading ? (
                    <Loader />
                  ) : data?.isPendingRequest ? (
                    "Requested"
                  ) : (
                    "Request Now"
                  )}
                </button>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-sm-12">
                <p className="text-justify">{data?.user?.about}</p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 text-capitalize">
                <div className="row">
                  <div className="col-sm-3 col-md-4 col-lg-3 col-xl-2 mb-3 lft-lin position-relative">
                    <p className="mb-0">
                      <img src="assets/images/Companyname.png" alt="" />
                    </p>
                    <p className="mb-0 fs-6">company</p>
                    <p className="mb-0 font-weight-bold text-justify">
                      {data?.user?.user_other_details?.company_name}
                    </p>
                  </div>
                  <div className="col-sm-3 col-md-4 col-lg-3 col-xl-2 mb-3 lft-lin position-relative">
                    <p className="mb-0">
                      <img src="assets/images/price.png" alt="" />
                    </p>
                    <p className="mb-0 fs-6">price</p>
                    <p className="mb-0 font-weight-bold">
                      <i className="fa fa-inr"></i> {data?.fee ? data?.fee : 0}
                      /hr
                    </p>
                  </div>
                  <div className="col-sm-3 col-md-4 col-lg-3 col-xl-2 mb-3 lft-lin position-relative">
                    <p className="mb-0">
                      <img src="assets/images/bag.png" alt="" />
                    </p>
                    <p className="mb-0 fs-6">total experience</p>
                    <p className="mb-0 font-weight-bold">
                      {" "}
                      {data?.user?.user_other_details?.experience === 1
                        ? `${data?.user?.user_other_details?.experience} year`
                        : data?.user?.user_other_details?.experience >= 1
                          ? `${data?.user?.user_other_details?.experience} years`
                          : 0}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3 lft-lin position-relative">
                    <p className="mb-0">
                      <img src="assets/images/location.png" alt="" />
                    </p>
                    <p className="mb-0 fs-6">location</p>
                    <p className="mb-0 font-weight-bold text-justify">
                      {data?.user?.city
                        ? `${data?.user?.city}, ${data?.user?.state}, ${data?.user?.country}`
                        : "NA"}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3 lft-lin position-relative">
                    <p className="mb-0">
                      <img src="assets/images/mentoredstudents.png" alt="" />
                    </p>
                    <p className="mb-0 fs-6">mentored students</p>
                    <p className="mb-0 font-weight-bold">
                      {data?.mentored_students}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3 lft-lin position-relative">
                    <p className="mb-0">
                      <img src="assets/images/totalhourstaught.png" alt="" />
                    </p>
                    <p className="mb-0 fs-6">total hours taught</p>
                    <p className="mb-0 font-weight-bold">
                      {data?.total_hours_tought === 1
                        ? `${data?.total_hours_tought} hr`
                        : `${data?.total_hours_tought} hrs`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mentorSpan mt-4">
                <div className="bg-span">
                  {data?.user?.career_list?.map((item, i) => (
                    <span className="mx-1" key={i}>
                      {item?.career_details?.title}
                    </span>
                  ))}
                </div>
              </div>
              {/* {!data?.isPendingRequest && data?.request_count > 0 && ( !data?.isPendingRequest && data?.user_rating?.studentId !== user?.id*/}
              {!data?.isPendingRequest && !data?.user_rating && data?.user_request_completed && (
                <>
                  <div className="col-sm-12">
                    <hr />
                  </div>
                  <div className="col-sm-12 mt-0">
                    <form
                      className="form-style text-left ratingFormStyle"
                      onSubmit={formik.handleSubmit}
                    >
                      <div className="row justify-content-start">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                          <label>Rate counsellor</label>
                          <p className="mb-0 rating-icons">
                            <Rating
                              initialRating={formik.values.rating}
                              emptySymbol="fa fa-star-o mx-1 fa-lg"
                              fullSymbol={"fa fa-star mx-1 fa-lg"}
                              fractions={2}
                              onChange={(val) => {
                                formik.setFieldValue("rating", val);
                              }}
                            />
                          </p>
                          {formik.errors.rating && formik.touched.rating && (
                            <span className="text-danger">
                              {formik.errors?.rating}
                            </span>
                          )}
                        </div>
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                          <div className="position-relative">
                            <textarea
                              className="textarea-control form-control pl-2 mt-2 borderSolid"
                              rows="7"
                              placeholder="Write Review.."
                              name="review"
                              onChange={formik.handleChange}
                              value={formik.values.review}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <button
                            type="submit"
                            className="btn add-btn fs-6 p-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CounsellerDetails;
