import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgressBar, Toast } from "react-bootstrap";
import { toast } from "react-toastify";
import Success from "../../component/modal/Success";
import DeleteModal from "../../component/modal/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { addStudentSkill, getAllSkillList, getUserDetail } from "../slice";
import Loader from "../../component/Loader";
import BackButton from "../../component/BackButton";

function Signup4() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalName, setModalName] = useState("");
  const [delIndex, setDelIndex] = useState("");
  const [show, setShow] = useState(false);
  const [skipShow, setSkipShow] = useState(false);
  const loading = useSelector((state) => state.loading);

  const signUpToken = JSON.parse(localStorage.getItem("user"));
// console.log("signUpToken5", signUpToken);

  const handleShow = () => {
    setShow(!show);
  };
  const schema = yup.object().shape({
    skills: yup.array().of(
      yup.object().shape({

        name: yup.string().required("Required"),
        star: yup
          .number()
          .positive()
          .required("Required")
          .typeError("Required"),
        id: yup.string(),
        skill_type: yup.string(),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onTouched",
    shouldFocusError: "true",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { append, fields, remove } = useFieldArray({
    control: control,
    name: "skills",
  });

  const onSubmit = (data) => {
    console.log(data);

    let newArr = data.skills.map((item) => {
      return {
        skill_id: item.id ? item?.id : "",
        skill_name: item.id ? "" : item.name,
        level: item.star ? item.star : "",
        skill_type: item?.skill_type,
        useremail: signUpToken.email ? signUpToken.email: ""
      };
    });
    console.log(newArr, "Skill payload");

    dispatch(addStudentSkill({ skill_data: newArr, useremail: signUpToken.email ? signUpToken.email:"" })).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        // window.localStorage.setItem("user", JSON.stringify(res?.payload?.data.user));
        setModalName("success");
        handleShow();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const handleChange = (ind, val, name) => {
    let arr = [...getValues("skills")];

    arr[ind][name] = val;
    setValue("skills", arr);
    watch("skills");
  };

  const handleDelete = () => {
    remove(delIndex);
  };

  useEffect(() => {
    dispatch(getUserDetail())
      .then((res) => {
        console.log(res);
        if (res?.payload?.code === 1) {
          let careerIds = res?.payload?.data?.user?.student_career_details?.map(
            (it) => {
              // console.log(it);
              return it.career_id.toString();
            }
          );
          console.log(careerIds);
          return careerIds;
        }
      })
      .then((data) => {
        dispatch(getAllSkillList({ career_id: JSON.stringify(data) })).then(
          (res) => {
            if (res?.payload?.code === 1) {
              let newArr = res?.payload?.data?.booster_skill_list?.map(
                (item) => {
                  return {
                    name: item?.skill_details?.title,
                    star: "",
                    id: item.skill_id,
                    skill_type: item?.skill_type,
                  };
                }
              );
              let newArr2 = res?.payload?.data?.core_skill_list?.map((item) => {
                return {
                  name: item?.skill_details?.title,
                  star: "",
                  id: item.skill_id,
                  skill_type: item?.skill_type,
                };
              });
              setValue("skills", [...newArr, ...newArr2]);
            }
          }
        );
      });
  }, [dispatch]);

  const watchSkills = watch("skills");
  console.log(watchSkills);
  console.log(errors, "Errors");
  const removeDefault = () => {
    const arr = watchSkills?.filter((it) => {
      return it?.star !== "";
    });
    setValue("skills", [...arr]);
    
  };

  const handleSkipSkillAndSubmit = () => {
    console.log("data",);
    removeDefault();
    setSkipShow(!skipShow)
  };

  return (
    <section>
      <div className="bg-white px-3 py-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9 mb-5">
              {/* <BackButton /> */}
              <img
                  src="assets/images/leftArrow.png"
                  alt=""
                  width="35px"
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/sign-up/4");
                  }}
                />

              <h1 className="h3 my-3 text-center theme-color font-weight-bold">
                Add Skills
              </h1>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="signup-steps">
                  <div className="step">
                    <span>Step 1</span>
                    <span className="num-step active done">
                      <img
                        src="assets/images/check.png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="step">
                    <span>Step 2</span>
                    <span className="num-step active done">
                      <img
                        src="assets/images/check.png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="step">
                    <span>Step 3</span>
                    <span className="num-step active done">
                      <img
                        src="assets/images/check.png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="step">
                    <span>Step 4</span>
                    <span className="num-step active done">
                      <img
                        src="assets/images/check.png"
                        className="img-fluid"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="step">
                    <span>Step 5</span>
                    <span className="num-step active"></span>
                  </div>
                </div>
              </div>
              <form
                className="form-style signupLabel"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row align-items-center">
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <span>Choose Skills</span>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 text-end">
                    <p
                      className="theme-color fw-bold cursor-pointer"
                      onClick={() => {
                        removeDefault();
                      }}
                    >
                      Clear Defaults
                    </p>
                  </div>
                  {fields?.map((item, index) => (
                    <>
                      <div
                        className="form-group col-sm-12 col-md-6 col-lg-6 mb-4"
                        key={index}
                      >
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control rounded"
                            placeholder="Skill Name"
                            onChange={(e) => {
                              const val = e.target.value;
                              handleChange(index, val, "name");
                            }}
                            {...register(`skills[${index}].name`)}
                          />
                        </div>
                        <span className="text-danger">
                          {errors.skills &&
                            errors?.skills[index]?.name?.message}
                        </span>
                      </div>
                      <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div className="starIcon">
                          <span className="starBox text-center">
                            <img
                              src={
                                item.star === 1
                                  ? "assets/images/Starorange.png"
                                  : "assets/images/Stargray.png"
                              }
                              onClick={() => {
                                handleChange(index, 1, "star");
                              }}
                              alt=""
                            />
                            <small>Beginner</small>
                          </span>
                          <span className="starBox text-center">
                            <img
                              src={
                                item.star === 2
                                  ? "assets/images/Starorange.png"
                                  : "assets/images/Stargray.png"
                              }
                              onClick={() => {
                                handleChange(index, 2, "star");
                              }}
                              alt=""
                            />
                            <small>Competent</small>
                          </span>
                          <span className="starBox text-center">
                            <img
                              src={
                                item.star === 3
                                  ? "assets/images/Starorange.png"
                                  : "assets/images/Stargray.png"
                              }
                              onClick={() => {
                                handleChange(index, 3, "star");
                              }}
                              alt=""
                            />
                            <small>Proficient</small>
                          </span>
                          <span className="starBox text-center">
                            <img
                              src={
                                item.star === 4
                                  ? "assets/images/Starorange.png"
                                  : "assets/images/Stargray.png"
                              }
                              onClick={() => {
                                handleChange(index, 4, "star");
                              }}
                              alt=""
                            />
                            <small>Expert</small>
                          </span>
                          <span
                            id="remove_2"
                            className="remove"
                            onClick={() => {
                              setModalName("delete");
                              handleShow();
                              setDelIndex(index);
                            }}
                          >
                            <img src="assets/images/menu_close.png" alt="" />
                          </span>
                        </div>
                        <span className="text-danger">
                          {errors.skills &&
                            errors?.skills[index]?.star?.message}
                        </span>
                      </div>
                      {/* {fields.length > 1 && (
                        <div className="form-group col-sm-12 col-md-12 col-lg-12">
                          <span
                            id="remove_2"
                            className="remove"
                            onClick={() => {
                              setModalName("delete");
                              handleShow();
                              setDelIndex(index);
                            }}
                          >
                            <img src="assets/images/menu_close.png" alt="" />
                          </span>
                        </div>
                      )} */}
                    </>
                  ))}
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4"></div>

                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <span
                      className="AddtoList btn"
                      onClick={() => {
                        append({
                          name: "",
                          star: "",
                          skill_type: "core",
                        });
                      }}
                    >
                      Add Skills
                    </span>
                  </div>
                  {errors &&
                    errors?.skills?.length > 0 &&
                    watchSkills?.filter((it) => it?.star === "")?.length >
                      0 && (
                      <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                        <div className="position-relative">
                          <span className="">
                            <span>Kindly fill all details or clear all defaults: </span>
                            {/* <p>
                              {watchSkills
                                ?.filter((it) => it?.star === "")
                                ?.map((it) => it?.name)
                                ?.join(", ")}
                            </p> */}
                          </span>
                        </div>
                      </div>
                    )}
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5 mt-4 text-center">
                    {/* {!skipShow && ( */}
                    <button
                      type="submit"
                      className={skipShow ? "d-none": "btn add-btn sign-btn mr-2"}
                      onClick={() => {
                        handleSkipSkillAndSubmit()
                      }}
                    >
                      { "Skip & Finish"}
                    </button>
                    <button type="submit" className= "btn add-btn sign-btn">
                      {loading ? <Loader /> : "Finish"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {show && modalName === "success" && (
        <Success show={show} handleShow={handleShow} />
      )}
      {modalName === "delete" && (
        <DeleteModal
          show={show}
          handleShow={handleShow}
          handleDelete={handleDelete}
        />
      )}
    </section>
  );
}

export default Signup4;
