import Logo from "../assets/svg-images/saphal-logo.svg";
import SearchIcon from "../assets/svg-images/new-search.svg";
import AskEditIcon from "../assets/svg-images/new-ask-edit.svg";
import ProfileDownArrow from "../assets/svg-images/prfl-down-arrow.svg";
import HomeIcon from "../assets/svg-images/new-home-icon.svg";
import CounsellerIcon from "../assets/svg-images/new-counseller.svg";
import MentorIcon from "../assets/svg-images/new-mentor.svg";
import NotificationIcon from "../assets/svg-images/new-notifi.svg";
import HomeCounsellerIcon from "../assets/svg-images/home-counseller.svg";
import HomeMentorIcon from "../assets/svg-images/home-mentor.svg";
import HomeViewAllIcon from "../assets/svg-images/arrow-view-all.svg";
import profileNotVerifiedIcon from "../assets/svg-images/profile-not-verified.svg";
import profileVerifiedIcon from "../assets/svg-images/profile-verified-icon.svg";
import SignupSchoolIcon from "../assets/svg-images/signup-school.svg";
import SignupCounsellerIcon from "../assets/svg-images/signup-counseller.svg";
import SignupMentorIcon from "../assets/svg-images/signup-mentor.svg";
import SignupRecuiterIcon from "../assets/svg-images/signup-recruiter.svg";
import SignupGoogleIcon from "../assets/svg-images/google-login.svg";
import SignupCloseIcon from "../assets/svg-images/signup-close.svg";
import SignupStep1Icon from "../assets/svg-images/signup-step-done.svg";
import SignupStep2Icon from "../assets/svg-images/signup-step-gray.svg";
import ServiceTrashIcon from "../assets/svg-images/service-trash.svg";
import ServiceEditIcon from "../assets/svg-images/service-edit.svg";
import YoutuberIcon from "../assets/svg-images/youtuber.svg";
import SignInEmailIcon from "../assets/svg-images/signin-email.svg";
import SignInLockIcon from "../assets/svg-images/signin-lock.svg";
import HumburgerIcon from "../assets/svg-images/humburger-icon.svg";
import RecentSortIcon from "../assets/svg-images/recent-sort-icon.svg";
import CareerHomeTagsIcon from "../assets/svg-images/work-career-icon.svg";
import MeetCounMenAllIcon from "../assets/svg-images/meet-coun-men.svg";
import CallenderMonthIcon from "../assets/svg-images/date-time-side.svg";
import WatchTimeIcon from "../assets/svg-images/watch-time.svg";
import ThreeDotFlagIcon from "../assets/svg-images/three-dot-hz.svg";
import XaphalCoinIcon from "../assets/svg-images/xaphal-coin-icon.svg";
import CertificateLogoIcon from "../assets/svg-images/certificate-logo.svg";
import CertificateEditIcon from "../assets/svg-images/certificate-edit.svg";
import CertificateTrashIcon from "../assets/svg-images/certificate-trash.svg";
import CertificateCredentialsIcon from "../assets/svg-images/certificate-credentials.svg";
import InstagramIcon from "../assets/svg-images/instagram-icon.svg";
import FacebookIcon from "../assets/svg-images/facebook-icon.svg";
import TwitterIcon from "../assets/svg-images/twitter-icon.svg";
import BehanceIcon from "../assets/svg-images/behance-icon.svg";
import GithubIcon from "../assets/svg-images/github-icon.svg";
import DribbleIcon from "../assets/svg-images/dribble-icon.svg";
import YoutubeIcon from "../assets/svg-images/youtube-icon.svg";
import PinterestIcon from "../assets/svg-images/pinterest-icon.svg";
import DropboxIcon from "../assets/svg-images/dropbox-icon.svg";
import CollabhandIcon from "../assets/svg-images/collab-hand.svg";
import CalanderIcon from "../assets/svg-images/calander-icon.svg";
import ClockIcon from "../assets/svg-images/clock-icon.svg";
import ClockBellIcon from "../assets/svg-images/clock-icon-opp.svg";
import FilterListIcon from "../assets/svg-images/filter-list-icon.svg";
import filterCounselloreMentorIcon from "../assets/svg-images/filter-counsellore-mentor-icon.svg";
import SignOutIcon from "../assets/svg-images/sign-out-icon.svg";
import MessagingIcon from "../assets/svg-images/messaging-icon.svg";
import MessageEditIcon from "../assets/svg-images/message-edit-icon.svg";
import LocationIcon from "../assets/svg-images/location-icon.svg";
import MailIcon from "../assets/svg-images/mail-icon.svg";
import OnlineShowIcon from "../assets/svg-images/online-green-icon.svg";
import VerticalEllipsesIcon from "../assets/svg-images/vertical-ellipses-icon.svg";
import SmileyEmojiIcon from "../assets/svg-images/smiley-emoji-icon.svg";
import ChatAttachmentIcon from "../assets/svg-images/chat-attachment-icon.svg";
import SendBtnChatIcon from "../assets/svg-images/send-btn-icon.svg";
import EditPenIcon from "../assets/svg-images/pen-edit-icon.svg";
import SearchIconChat from "../assets/svg-images/search-icon-chat.svg";
import RightTickIcon from "../assets/svg-images/right-tick-icon.svg";
import CrossIcon from "../assets/svg-images/cross-icon.svg";
import LikeIcon from "../assets/svg-images/like-icon.svg";
import CommentIcon from "../assets/svg-images/comment-icon.svg";
import BookMarkIcon from "../assets/svg-images/bookmark-icon.svg";
import HorizonElllipsesIcon from "../assets/svg-images/horizon-ellipses-icon.svg";
import XAPHALTextIcon from "../assets/svg-images/XAPHAL-text-icon.svg";
import ChatBoyIcon from "../assets/svg-images/chat-boy-icon.svg";
import LockIcon from "../assets/svg-images/lock-icon.svg";
import NoChatSvg from "../assets/svg-images/nochat-img.svg";
import DislikeIcon from "../assets/svg-images/dislike-icon.svg";
import WalletIcon from "../assets/svg-images/wallet-icon.svg";
import EyeIcon from "../assets/svg-images/eye-icon.svg";
import ShareUrlProfileIcon from "../assets/svg-images/share-profile-icon.svg";
import StudentHatIcon from "../assets/svg-images/student-hat-icon.svg";
import WhiteLogo from "../assets/svg-images/white-logo.svg";
import GreenRightArrow from "../assets/svg-images/green-right-arrow.svg";
import InvertedCommas from "../assets/svg-images/inverted-commas.svg";
import YtFooter from "../assets/svg-images/yt-footer.svg";
import TwitterFooter from "../assets/svg-images/twitter-footer.svg";
import InstaFooter from "../assets/svg-images/insta-footer.svg";
import FbFooter from "../assets/svg-images/facebook-footer.svg";
import Home1Icon from "../assets/svg-images/home-svg1.svg";
import Home2Icon from "../assets/svg-images/home-svg2.svg";
import Home3Icon from "../assets/svg-images/home-svg3.svg";
import Home4Icon from "../assets/svg-images/home-svg4.svg";

import { string } from "yup/lib/locale";

export const SvgImages = {
  Home1Icon: String(Home1Icon),
  Home2Icon: String(Home2Icon),
  Home3Icon: String(Home3Icon),
  Home4Icon: String(Home4Icon),
  YtFooter: String(YtFooter),
  TwitterFooter: String(TwitterFooter),
  InstaFooter: String(InstaFooter),
  FbFooter: String(FbFooter),
  Logo: String(Logo),
  GreenRightArrow: String(GreenRightArrow),
  InvertedCommas: String(InvertedCommas),
  WhiteLogo: String(WhiteLogo),
  SearchIcon: String(SearchIcon),
  AskEditIcon: String(AskEditIcon),
  ProfileDownArrow: String(ProfileDownArrow),
  HomeIcon: String(HomeIcon),
  CounsellerIcon: String(CounsellerIcon),
  MentorIcon: String(MentorIcon),
  NotificationIcon: String(NotificationIcon),
  HomeCounsellerIcon: String(HomeCounsellerIcon),
  HomeMentorIcon: String(HomeMentorIcon),
  HomeViewAllIcon: String(HomeViewAllIcon),
  profileNotVerifiedIcon: String(profileNotVerifiedIcon),
  profileVerifiedIcon: String(profileVerifiedIcon),
  SignupSchoolIcon: String(SignupSchoolIcon),
  SignupCounsellerIcon: String(SignupCounsellerIcon),
  SignupMentorIcon: String(SignupMentorIcon),
  SignupRecuiterIcon: String(SignupRecuiterIcon),
  SignupGoogleIcon: String(SignupGoogleIcon),
  SignupCloseIcon: String(SignupCloseIcon),
  SignupStep1Icon: String(SignupStep1Icon),
  SignupStep2Icon: String(SignupStep2Icon),
  ServiceTrashIcon: String(ServiceTrashIcon),
  ServiceEditIcon: String(ServiceEditIcon),
  YoutuberIcon: String(YoutuberIcon),
  SignInEmailIcon: String(SignInEmailIcon),
  SignInLockIcon: String(SignInLockIcon),
  HumburgerIcon: String(HumburgerIcon),
  RecentSortIcon: String(RecentSortIcon),
  CareerHomeTagsIcon: String(CareerHomeTagsIcon),
  MeetCounMenAllIcon: String(MeetCounMenAllIcon),
  CallenderMonthIcon: String(CallenderMonthIcon),
  WatchTimeIcon: String(WatchTimeIcon),
  ThreeDotFlagIcon: String(ThreeDotFlagIcon),
  XaphalCoinIcon: String(XaphalCoinIcon),
  CertificateTrashIcon: String(CertificateTrashIcon),
  CertificateEditIcon: String(CertificateEditIcon),
  CertificateLogoIcon: String(CertificateLogoIcon),
  CertificateCredentialsIcon: String(CertificateCredentialsIcon),
  InstagramIcon: String(InstagramIcon),
  FacebookIcon: String(FacebookIcon),
  TwitterIcon: String(TwitterIcon),
  BehanceIcon: String(BehanceIcon),
  GithubIcon: String(GithubIcon),
  DribbleIcon: String(DribbleIcon),
  YoutubeIcon: String(YoutubeIcon),
  PinterestIcon: String(PinterestIcon),
  DropboxIcon: String(DropboxIcon),
  CollabhandIcon: String(CollabhandIcon),
  CalanderIcon: String(CalanderIcon),
  ClockIcon: String(ClockIcon),
  ClockBellIcon: String(ClockBellIcon),
  filterCounselloreMentorIcon: String(filterCounselloreMentorIcon),
  FilterListIcon: String(FilterListIcon),
  SignOutIcon: String(SignOutIcon),
  MessagingIcon: String(MessagingIcon),
  MessageEditIcon: String(MessageEditIcon),
  MailIcon: String(MailIcon),
  LocationIcon: String(LocationIcon),
  OnlineShowIcon: String(OnlineShowIcon),
  VerticalEllipsesIcon: String(VerticalEllipsesIcon),
  SendBtnChatIcon: String(SendBtnChatIcon),
  SmileyEmojiIcon: String(SmileyEmojiIcon),
  ChatAttachmentIcon: String(ChatAttachmentIcon),
  EditPenIcon: String(EditPenIcon),
  SearchIconChat: String(SearchIconChat),
  CrossIcon: String(CrossIcon),
  RightTickIcon: String(RightTickIcon),
  LikeIcon: String(LikeIcon),
  CommentIcon: String(CommentIcon),
  BookMarkIcon: String(BookMarkIcon),
  HorizonElllipsesIcon: String(HorizonElllipsesIcon),
  XAPHALTextIcon: String(XAPHALTextIcon),
  ChatBoyIcon: String(ChatBoyIcon),
  LockIcon: String(LockIcon),
  NoChatSvg: String(NoChatSvg),
  DislikeIcon: String(DislikeIcon),
  WalletIcon: String(WalletIcon),
  EyeIcon: String(EyeIcon),
  ShareUrlProfileIcon: String(ShareUrlProfileIcon),
  StudentHatIcon: String(StudentHatIcon),
};
