import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  changeRequestStatus,
  getAllRunningFestivals,
  getDiscoverCertifications,
  getUserDetail,
  requestList,
  toggleSidebar,
} from "../../feature/slice";
import SidebarCertificationModal from "../../feature/certification/component/SidebarCertificationModal";

import paymentIcons from "../../assets/svg-images/payment-icon.svg";
import {SvgImages} from "../../constants/SvgImages";
import {Link, redirect, useLocation, useNavigate} from "react-router-dom";
import VerifyMobile from "./VerifyMobile";
import MobileNumberModal from "./MobileNumberModal";
import moment from "moment";
import EnterAmount from "./EnterAmount";
import WithdrawAmount from "./WithdrawAmount";
import styles from "./styles.module.css";
import "../../assets/css/newSideBar_festival.css";
import PersonaPopupForm from "../../feature/profile/userProfile/UserPersonaPopup";
import SocialSitesPopup from "../../feature/profile/userProfile/SocialMediaPopup";
import CollaboratePopup from "../../feature/profile/userProfile/CollaboratePopup";
import AllFestivalEvent from "./AllFestivalEvent";
import {toast} from "react-toastify";
import "../../assets/css/NewSidebarMessagingStyle.css";


function NewSidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [contactsChat, setContactChat] = useState(false);

  const [festivalShow, setFestivalShow] = useState(false);
  const [showPersonaPopup, setShowPersonaPopup] = useState(false);
  const handleClosePersonaPopup = () => setShowPersonaPopup(false);
  const handleShowPersonaPopup = () => setShowPersonaPopup(true);

  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const handleCloseSocialMedia = () => setShowSocialMedia(false);
  const handleShowSocialMedia = () => setShowSocialMedia(true);

  const [showCollabPopup, setShowCollabPopup] = useState(false);
  const handleCloseCollabPopup = () => setShowCollabPopup(false);
  const handleShowCollabPopup = () => setShowCollabPopup(true);

  //console.log("user.user_persona_list", user.user_persona_list[0].career_persona);
  const [CareerFilterArray, setCareerFilterArray] = useState([]);
  const [otpShow, setOtpShow] = useState(false);
  const handleOtpShow = () => {
    setOtpShow(!otpShow);
  };
  const upcommingSessionList = useSelector(
    (state) => state.upcomming_session_list
  );
  const ShowUpcommingSession = useSelector(
    (state) => state.setShowUpcommingSession
  );
  const StudentUpcommingSession = useSelector(
    (state) => state.setShowStudentUpcommingSession
  );

  // const certificationData = useSelector((state) => state.getAllCertificationsList);

  const [isMeetingRunning, setIsMeetingRunning] = useState(false);
  const [showAmountModal, setShowAmountModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [upcomingSession, setUpcomingSession] = useState([]);
  const [certificationData, setCertificationData] = useState([]);

  const [MobileNo, setMobileNo] = useState(false);
  const handleMobileNo = () => {
    setMobileNo(!MobileNo);
  };
  const [detail, setDetail] = useState(null);
  const userImage = useSelector((store) => store.userImage);
  const studentDetail = useSelector((store) => store.userDetail);
  // const otherUserDetail = useSelector((store) => store.otherDetail);

  useEffect(() => {
    if(ShowUpcommingSession && user?.user_type !== "student") {
      setUpcomingSession([]);
    } else if(user?.user_type !== "student") {
      setUpcomingSession([...upcommingSessionList]);
    }
  }, [ShowUpcommingSession, user?.user_type]);
  // const [detail, setDetail] = useState(null);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  const handleFestivalShow = () => {
    setFestivalShow(!festivalShow);
  };

  useEffect(() => {
    console.log("student detail ", studentDetail);
    setDetail(studentDetail);
    // setCareerFilterArray([...studentDetail?.student_career_details.map((item) => item?.career_details?.title)])
  }, [user?.user_type, studentDetail]);

  const rightSidebarShow = useSelector((state) => state.sidebarShow);

  const handleRightSidebar = () => {
    dispatch(toggleSidebar(!rightSidebarShow));
  };

  useEffect(() => {
    if(!detail) {
      // console.log('rrrravi');
      dispatch(getUserDetail({}))
      // .then((resp) => {
      //   // console.log('user set', resp?.payload?.data?.user);
      //   // setDetail(resp?.payload?.data?.user);
      //   // setCareerFilterArray([...resp?.payload?.data?.user?.student_career_details.map((item) => item?.career_details?.title)])
      // });
    }
  }, [dispatch, user]);

  useEffect(() => {
    if(StudentUpcommingSession) {
      dispatch(
        requestList({
          page: 1,
          limit: 10,
          status: 2,
        })
      ).then((res) => {
        setUpcomingSession(res?.payload?.data?.request?.slice(0, 2));
      });
    } else {
      dispatch(
        requestList({
          page: 1,
          limit: 10,
          status: 2,
        })
      ).then((res) => {
        setUpcomingSession(res?.payload?.data?.request?.slice(0, 2));
      });
    }
  }, [dispatch, StudentUpcommingSession]);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // console.log(window.scrollY > 250, " ooooo ");
      setScroll(window.scrollY > 250);
      // setScroll(window.scrollY > 400);
    });
  }, []);

  useEffect(() => {
    location.pathname.includes("chat")
      ? setContactChat(true)
      : setContactChat(false);
  }, [location]);

  const handleAddMoneyToWallet = () => {
    setShowAmountModal(!showAmountModal);
  };

  const handleWithdrawal = () => {
    setShowWithdrawModal(true);
  };

  const handleAllRunningFestival = () => {
    dispatch(getAllRunningFestivals({})).then((res) => {
      // console.log(res?.payload?.data?.get_meeting_info);
      let meetingRunning = res?.payload?.data?.get_meeting_info;
      if(meetingRunning.length > 0) {
        if(meetingRunning[0]?.isChanged && meetingRunning[0]?.isPublished) {
          window.open(meetingRunning[0]?.meeting_link, "_self");
        } else {
          if(user?.user_type !== "student") {
            window.open(
              `https://xaphal.com/festival/${meetingRunning[0]?.meetingName}/${meetingRunning[0]?.meetingId}/${meetingRunning[0].moderatorPW}/mentor`,
              "_self"
            );
          } else {
            window.open(
              `https://xaphal.com/festival/${meetingRunning[0]?.meetingName}/${meetingRunning[0]?.meetingId}/${meetingRunning[0].studentPW}/student`,
              "_self"
            );
          }
        }
      } else {
        toast.error("Meeting is not yet started");
      }
    });
  };

  useEffect(() => {
    dispatch(getAllRunningFestivals({})).then((res) => {
      // console.log(res?.payload?.data?.get_meeting_info);
      let meetingRunning = res?.payload?.data?.get_meeting_info;
      if(meetingRunning.length > 0) {
        setIsMeetingRunning(true);
      } else {
        setIsMeetingRunning(false);
      }
    });
  }, []);

  useEffect(() => {
    // getAllCertificationsList
    console.log("CareerFilterArray ", CareerFilterArray);
    dispatch(
      getDiscoverCertifications({
        randomCertification: "random",
        limit: 2,
        userId: user?.id,
        discover: "discover",
        // CareerFilterArray: JSON.stringify(CareerFilterArray),
        visibiltyRole:
          user?.user_type === "counseller" ? "counsellor" : user?.user_type,
      })
    ).then((res) => {
      console.log("discover_data_file", res?.payload?.data?.certifications);
      setCertificationData([...res?.payload?.data?.certifications]);
      //setCertificationData([]);
    });
  }, []);

  // console.log("certificationData....... ", certificationData);
  // console.log("image*&", detail);
  // console.log("image2", upcomingSession);
  return (
    <>
      {/* <ChatContacts /> */}

      <div className=" sidebar_final_select  admin-sidebar sideBarAbso leftSidebar mainContentPage">
        <div className="shadow-part bg-transparent ">
          <nav>
            <div className="d-flex align-items-center justify-content-start px-4 gap-2 bg-white  rounded-sm mb-3 sidebar_box_main border_box_home_page">
              <div className=" profile-box text-center">
                {detail?.image?.includes("https") ? (
                  detail?.image === "undefined" || detail?.image === " " ? (
                    <span className="prfl_side_letters ">
                      {detail?.first_name?.charAt(0).toUpperCase()}
                      {detail?.last_name?.charAt(0).toUpperCase()}
                    </span>
                  ) : userImage ? (
                    <img
                      src={userImage}
                      className={styles.prfl_image_left_bar}
                      alt="user"
                    />
                  ) : (
                    <img
                      src={detail?.image}
                      className={styles.prfl_image_left_bar}
                      alt="user"
                    />
                  )
                ) : (
                  <span className="prfl_side_letters ">
                    {detail?.first_name?.charAt(0) ? detail?.first_name?.charAt(0).toUpperCase() : <img
                      src={"assets/images/profile1.png"}
                      className="emp-img "
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%"
                      }}
                    />}
                    {detail?.last_name?.charAt(0) ? detail?.last_name?.charAt(0).toUpperCase() : null}
                  </span>
                )}
              </div>
              <div className="pt-2">
                <span
                  className={` ml-1 ${styles.hello_left_bar}`}
                  style={{fontWeight: "500", letterSpacing: "0.5px"}}
                >
                  Hello,
                </span>
                <p className="row ml-1">
                  <span
                    className={styles.user_name_left_bar}
                    onClick={() => {
                      if(detail?.username) {
                        navigate(`${detail?.username}`);
                      }
                    }}
                  >
                    {detail?.first_name}{" "}
                    <span
                      style={{whiteSpace: "nowrap", wordWrap: "break-word"}}
                    >
                      {detail?.last_name}
                    </span>
                  </span>
                  <span
                    className="cursor-pointer pl-1"
                    onClick={() => {
                      //handleOtpShow();
                      if(!detail?.isMobileVerified) handleMobileNo();
                    }}
                  >
                    <img
                      src={
                        detail?.isMobileVerified
                          ? SvgImages.profileVerifiedIcon
                          : SvgImages.profileNotVerifiedIcon
                      }
                      className="pefl-icons"
                      alt=""
                    />
                  </span>
                </p>
              </div>
            </div>
            <div
              className={scroll ? "fixedAfterScroll" : " "}
              style={{top: scroll ? "-219px" : " "}}
            >
              <div className="bg-white px-4 py-2 rounded-sm mb-3 border_box_home_page">
                <h5 className="mt-1 mb-3 side_head_quick font-size-14">
                  Quick Links
                </h5>

                {detail?.student_degree_details?.length === 0 &&
                  detail?.user_type?.toLowerCase() === "student" && (
                    <div
                      className={styles.activity_left_request}
                      style={{
                        border: "1.5px solid #FACBE7",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/student/edit-profile/1");
                      }}
                    >
                      <span
                        className={styles.dot_activity_left}
                        style={{backgroundColor: "#FACBE7"}}
                      ></span>
                      <span
                        className={styles.text_acitivity_left}
                        style={{fontWeight: "400", fontSize: "12px"}}
                      >
                        Complete Profile
                      </span>
                    </div>
                  )}
                {!detail?.user_other_details &&
                  detail?.user_type?.toLowerCase() !== "student" && (
                    <div
                      className={styles.activity_left_request}
                      style={{
                        border: "1.5px solid #FACBE7",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if(user?.user_type === "counseller") {
                          navigate("/counseller/edit-profile");
                        } else if(user?.user_type === "mentor") {
                          navigate("/mentor/edit-profile");
                        } else if(user?.user_type === "recruiter") {
                          navigate("/recruiter/edit-profile");
                        }
                      }}
                    >
                      <span
                        className={styles.dot_activity_left}
                        style={{backgroundColor: "#FACBE7"}}
                      ></span>
                      <span
                        className={styles.text_acitivity_left}
                        style={{fontWeight: "400", fontSize: "12px"}}
                      >
                        Complete Profile
                      </span>
                    </div>
                  )}

                <Link to="/view-profile">
                  <div
                    className={styles.activity_left_request}
                    style={{border: "1.5px solid #E5D0FF"}}
                  >
                    <span
                      className={styles.dot_activity_left}
                      style={{backgroundColor: "#E5D0FF"}}
                    ></span>
                    <span
                      className={styles.text_acitivity_left}
                      style={{fontWeight: "400", fontSize: "12px"}}
                    >
                      My Activity
                    </span>
                  </div>
                </Link>

                {detail?.user_persona_list?.length <= 0 && (
                  <Link>
                    <div
                      className={styles.activity_left_request}
                      style={{border: "1.5px solid #F3F7C9"}}
                    >
                      <span
                        className={styles.dot_activity_left}
                        style={{backgroundColor: "#F3F7C9"}}
                      ></span>
                      <span
                        className={styles.text_acitivity_left}
                        style={{fontWeight: "400", fontSize: "12px"}}
                        onClick={() => handleShowPersonaPopup()}
                      >
                        Add Persona
                      </span>
                    </div>
                  </Link>
                )}

                <PersonaPopupForm
                  show={showPersonaPopup}
                  handleClose={handleClosePersonaPopup}
                  handleShow={handleShowPersonaPopup}
                  detail={detail}
                />

                {user?.user_type?.toLowerCase() === "student" && (
                  <Link to="/career-paths">
                    <div
                      className={styles.activity_left_request}
                      style={{border: "1.5px solid #FFD0D2"}}
                    >
                      <span
                        className={styles.dot_activity_left}
                        style={{backgroundColor: "#FFD0D2"}}
                      ></span>
                      <span
                        className={styles.text_acitivity_left}
                        style={{fontWeight: "400", fontSize: "12px"}}
                      >
                        My Career Path
                      </span>
                    </div>
                  </Link>
                )}

                {/* {user?.user_type?.toLowerCase() === "student" && (
                  <Link to="/discover-certification">
                    <div
                      className={styles.activity_left_request}
                      style={{ border: "1.5px solid #B7DCFF" }}
                    >
                      <span
                        className={styles.dot_activity_left}
                        style={{ backgroundColor: "#B7DCFF" }}
                      ></span>
                      <span
                        className={styles.text_acitivity_left}
                        style={{ fontWeight: "400", fontSize: "12px" }}
                      >
                        New Certification
                      </span>
                    </div>
                  </Link>
                )} */}
                {user?.user_type?.toLowerCase() !== "student" && (
                  <Link to="/services">
                    <div
                      className={styles.activity_left_request}
                      style={{border: "1.5px solid #D7FFD0"}}
                    >
                      <span
                        className={styles.dot_activity_left}
                        style={{backgroundColor: "#D7FFD0"}}
                      ></span>
                      <span
                        className={styles.text_acitivity_left}
                        style={{fontWeight: "400", fontSize: "12px"}}
                      >
                        Services
                      </span>
                    </div>
                  </Link>
                )}
                {user?.user_type?.toLowerCase() !== "student" && (
                  <Link to="/requests">
                    <div
                      className={styles.activity_left_request}
                      style={{border: "1.5px solid #F3F7C9"}}
                    >
                      <span
                        className={styles.dot_activity_left}
                        style={{backgroundColor: "#F3F7C9"}}
                      ></span>
                      <span
                        className={styles.text_acitivity_left}
                        style={{fontWeight: "400", fontSize: "12px"}}
                      >
                        Manage Requests
                      </span>
                    </div>
                  </Link>
                )}

                {detail?.user_collaboration_list?.length <= 0 && (
                  <Link>
                    <div
                      className={styles.activity_left_request}
                      style={{border: "1.5px solid #D7FFD0"}}
                    >
                      <span
                        className={styles.dot_activity_left}
                        style={{backgroundColor: "#D7FFD0"}}
                      ></span>
                      <span
                        className={styles.text_acitivity_left}
                        onClick={() => handleShowCollabPopup()}
                        style={{fontWeight: "400", fontSize: "12px"}}
                      >
                        Collaboration Pref
                      </span>
                    </div>
                  </Link>
                )}

                <CollaboratePopup
                  show={showCollabPopup}
                  handleClose={handleCloseCollabPopup}
                  handleShow={handleShowCollabPopup}
                  detail={detail}
                />

                {detail?.user_social_media <= 0 && (
                  <Link>
                    <div
                      className={styles.activity_left_request}
                      style={{border: "1.5px solid #D7FFD0"}}
                    >
                      <span
                        className={styles.dot_activity_left}
                        style={{backgroundColor: "#D7FFD0"}}
                      ></span>
                      <span
                        className={styles.text_acitivity_left}
                        style={{fontWeight: "400", fontSize: "12px"}}
                        onClick={() => handleShowSocialMedia()}
                      >
                        Add Social Links
                      </span>
                    </div>
                  </Link>
                )}

                <SocialSitesPopup
                  show={showSocialMedia}
                  handleClose={handleCloseSocialMedia}
                  handleShow={handleShowSocialMedia}
                  detail={detail}
                />

                {user?.user_type?.toLowerCase() === "student" && (
                  <Link to="/meeting-requests">
                    <div
                      className={styles.activity_left_request}
                      style={{border: "1.5px solid #B7DCFF"}}
                    >
                      <span
                        className={styles.dot_activity_left}
                        style={{backgroundColor: "#B7DCFF"}}
                      ></span>
                      <span
                        className={styles.text_acitivity_left}
                        style={{fontWeight: "400", fontSize: "12px"}}
                      >
                        Meeting Request
                      </span>
                    </div>
                  </Link>
                )}
              </div>

              {/* certification */}
              <div className="bg-white px-4 py-2 rounded-sm mb-3 border_box_home_page">
                <div className="uppcomm_flex_name_view mb-3">
                  <span
                    className=" side_head_quick"
                    style={{fontSize: "14px"}}
                  >
                    Discover
                  </span>
                  {
                    certificationData.length != 0
                      ?
                      <Link
                        style={{fontSize: "12px", textDecoration: "underline"}}
                        to="/discover-certification"
                      >
                        View All
                      </Link>
                      : null
                  }

                </div>

                {certificationData &&
                  certificationData.length > 0 &&
                  certificationData?.slice(0, 2).map((item, i) => {
                    return (
                      <>
                        <div
                          className="d-flex mb-3 justify-content-between"
                          key={i}
                        >
                          <div
                            className="text-center"
                            style={{
                              minWidth: "69px",
                              background: "#f7f9fb",
                              borderRadius: "6px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "68px",
                              border: "1px solid #D3D3D3",
                            }}
                          >
                            {item?.organization_logo?.includes("http") ? (
                              <img
                                src={item?.organization_logo}
                                alt="goog"
                                style={{width: "43px"}}
                              />
                            ) : (
                              <img
                                src="/google-1.svg"
                                alt="goog"
                                style={{width: "43px"}}
                              />
                            )}
                          </div>

                          <div className="ml-2 col-lg-9 px-0">
                            <div
                              style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                height: "18px",
                                width: "150px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item?.title}
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                fontWeight: "300",
                                height: "29px",
                                width: "155px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {/* {item?.pre_header.length < 50
                                ? item?.pre_header
                                : item?.pre_header.substring(0, 50) + "..."} */}
                              {item?.pre_header}
                            </div>

                            <div
                              className="d-flex justify-content-between"
                              style={{alignItems: "end"}}
                            >
                              <span
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "600",
                                  lineHeight: "2",
                                }}
                              >
                                {item?.organization_name}
                              </span>
                              <span className="mr-2">
                                <SidebarCertificationModal item={item} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                }
                {
                  certificationData.length === 0
                    ? <div className="fs12 discover_no_data_found"> There's no recommendations available for you!! Try
                      <Link to="/discover-certification" style={{fontSize: "12px", fontWeight: "400", textDecoration: "underline"}}> Explore all</Link>
                    </div> : null
                }
              </div>

              {/*//X-FACTOR FESTIVAL*/}
              <div className=" px-4 py-3 rounded-lg mb-3 border_box_home_page x-factor-festival-container d-none">
                <div className="d-flex align-items-center justify-content-between mb-2 ">
                  <span className="x-factor-title"> Xaphal Event </span>
                  <span
                    className="view-text cursor-pointer"
                    onClick={() => {
                      setFestivalShow(!festivalShow);
                    }}
                  >
                    View All
                  </span>
                </div>

                <div className="d-flex justify-content-between  x-factor-date-responsive">
                  <span className="amount_txt_val d-flex align-items-center x-factor-date">
                    <img src={SvgImages.CalanderIcon} alt="" />
                    <span className="festival-date">MON - FRI</span>
                  </span>
                  <span className="amount_txt_val d-flex align-items-center">
                    <img src={SvgImages.ClockIcon} alt="" />
                    <span className="festival-time">5:00 PM</span>
                  </span>
                </div>
                <div className="x-factor-festival-btn-container">
                  <button
                    className="x-factor-festival-btn"
                    // style={{
                    //   backgroundColor : isMeetingRunning ? "032f3c" :"",
                    //   color: isMeetingRunning ? "white": ""
                    // }}
                    onClick={() => {
                      // toast("Meeting is not yet started")
                      handleAllRunningFestival();
                      //window.open("https://forms.gle/E5HmTSUuQthrGi8A9", "_blank");
                    }}
                  >
                    {/* {
                      isMeetingRunning ? "Join Event" : "Starting Soon"
                    } */}
                    JOIN EVENT
                  </button>
                </div>
              </div>

              {/* {   user?.user_type?.toLowerCase() === "student" && ( */}
              {upcomingSession?.length > 0 && (
                <div className="bg-white px-4 py-2 rounded-sm mb-3 border_box_home_page">
                  <div className="uppcomm_flex_name_view">
                    <span className=" side_head_quick font-size-14">
                      Upcoming Sessions
                    </span>
                    <span
                      className="upcomming_view_all"
                      onClick={() => {
                        (async () => {
                          localStorage.setItem("requestStats", true);
                        })();
                        dispatch(changeRequestStatus(true));
                        if(user?.user_type === "student") {
                          navigate("/meeting-requests");
                        } else {
                          navigate("/requests");
                        }
                      }}
                    >
                      <u style={{color: "#0275B1"}}>View All </u>
                    </span>
                  </div>
                  <div className="mt-2">
                    {upcomingSession?.length > 0 ? (
                      upcomingSession.map((item, i) => {
                        return (
                          <div className="UpcomingSessions mb-2" key={i}>
                            {item?.studentDetails ? (
                              item?.studentDetails?.image?.includes("http") ? (
                                <img
                                  src={item?.studentDetails?.image}
                                  className="Sessionsprfl-img"
                                  alt="user"
                                />
                              ) : (
                                <span className="prfl_session_name py-1">
                                  {item?.studentDetails?.first_name
                                    .charAt(0)
                                    .toUpperCase()}
                                  {item?.studentDetails?.last_name
                                    .charAt(0)
                                    .toUpperCase()}
                                </span>
                              )
                            ) : item?.userDetails ? (
                              item?.userDetails?.image?.includes("http") ? (
                                <img
                                  src={item?.userDetails?.image}
                                  className="Sessionsprfl-img"
                                  alt="user"
                                />
                              ) : (
                                <span className="prfl_session_name py-1">
                                  {item?.userDetails?.first_name
                                    .charAt(0)
                                    .toUpperCase()}
                                  {item?.userDetails?.last_name
                                    .charAt(0)
                                    .toUpperCase()}
                                </span>
                              )
                            ) : (
                              <img
                                src="assets/images/employe.png"
                                className="Sessionsprfl-img"
                                alt="user"
                              />
                            )}
                            <span>
                              <h6 className="mb-2 text-capitalize upcomm_cm_user_name">
                                {item?.studentDetails
                                  ? `${item?.studentDetails?.first_name} ${item?.studentDetails?.last_name}`
                                  : item?.userDetails
                                    ? `${item?.userDetails?.first_name} ${item?.userDetails?.last_name}`
                                    : ""}
                              </h6>
                              <span className=" text_para10 d-flex align-items-center justify-content-between">
                                <span className="d-flex align-items-center">
                                  <span className="pr-1">
                                    <img
                                      src={SvgImages.CallenderMonthIcon}
                                      alt=""
                                    />
                                  </span>
                                  <span>
                                    {item?.selectedTimeSlot
                                      ? moment(
                                        item?.selectedTimeSlot?.date,
                                        "YYYY-MM-DD"
                                      ).format("DD/MM/YYYY")
                                      : ""}
                                  </span>
                                </span>

                                <span className="d-flex align-items-center  text-uppercase">
                                  <span className="pr-1">
                                    <img src={SvgImages.WatchTimeIcon} alt="" />
                                  </span>
                                  <span>
                                    {item?.selectedTimeSlot
                                      ? moment(
                                        item?.selectedTimeSlot?.time,
                                        "hh:mm:ss"
                                      ).format("hh:mm A")
                                      : ""}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      <div className="fs12">No upcoming sessions.</div>
                    )}
                  </div>
                </div>
              )}
              <div className="bg-white px-4 py-2 rounded-sm mb-3 border_box_home_page">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="side_head_quick font-size-14">
                    My Wallet
                  </span>
                  <img src={paymentIcons} alt="" />
                </div>

                <div className="balanace_amount_btn_side">
                  <span
                    className="text_balance_s "
                    style={{fontSize: "12px", fontWeight: "500"}}
                  >
                    Balance
                  </span>
                  <span className="amount_txt_val d-flex align-items-center">
                    <img src={SvgImages.XaphalCoinIcon} alt="" />
                    <span className="pl-1">
                      {detail?.wallet_amount !== "null" &&
                        detail?.wallet_amount !== "0"
                        ? (
                          Math.round(detail?.wallet_amount * 100) / 100
                        ).toFixed(2)
                        : "0"}{" "}
                    </span>
                  </span>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between mb-1 "
                  onClick={() => {
                    user?.user_type === "student"
                      ? handleAddMoneyToWallet()
                      : handleWithdrawal();
                  }}
                >
                  <span></span>
                  <span className="addmoney_btn_s">
                    {user?.user_type === "student" ? "Add Money" : "Withdraw"}
                  </span>
                  <span></span>
                </div>
              </div>
            </div>
          </nav>
        </div >

        <AllFestivalEvent
          festivalShow={festivalShow}
          handleFestivalShow={handleFestivalShow}
        />

        <VerifyMobile
          show={otpShow}
          handleShow={handleOtpShow}
          userType={detail?.user_type}
          otpFor={"mobile verification"}
          userId={detail?.id}
          userEmail={detail?.email}
        />
        <MobileNumberModal
          show={MobileNo}
          handleShow={handleMobileNo}
          OTPshow={otpShow}
          OTPhandleShow={handleOtpShow}
          data={detail}
        />

        <EnterAmount
          show={showAmountModal}
          handleShow={() => {
            setShowAmountModal(!showAmountModal);
          }}
        />
        <WithdrawAmount
          show={showWithdrawModal}
          handleShow={() => {
            setShowWithdrawModal(!showWithdrawModal);
          }}
        />
      </div >
    </>
  );
}

export default NewSidebar;
