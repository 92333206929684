import React from "react";
import {Link} from "react-router-dom";

function AdvertiseOnXaphal() {
  return (
    <section>
      <div className="bg-white px-3 py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-12 col-lg-6 mb-5 d-grid align-items-center text-center text-lg-left">
              <div>
                <h2 className="h2 mt-5 theme-color font-weight-bold">
                  Advertise on Xaphal
                </h2>
              </div>
              <div>
                <p className="my-5 font-weight-600">
                  Please contact{" "}
                  <a href="mailto:bhavtosh.upadhyay@xaphal.com">
                    bhavtosh.upadhyay@xaphal.com
                  </a>{" "}
                  for any advertisement related query!
                </p>
              </div>
              <div>
                <div className="log-singn">
                  <Link
                    className="nav-link text-white get-in-touch d-block w-75 py-2 new_button_color"
                    to="/book-your-space"
                  >
                    Book Your Space
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
              <div className="shadow">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div className="ourprocess-box bg-white">
                      <img
                        src="assets/images/AdvertiseonXaphal-image.png"
                        className="img-fluid"
                        alt=" "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdvertiseOnXaphal;
