import CounsellerImg1 from "../assets/images/counselo1.png";
import CounsellerImg2 from "../assets/images/counselor2.png";
import CounsellerImg3 from "../assets/images/counsellor3.png";
import Comments from "../assets/images/comments.png";
import Share from "../assets/images/Share.png";
import Upvote from "../assets/images/upvote.png";
import HelpPro from "../assets/images/help-pro.png";
import abhinavk from "../assets/images/abhinavk.png";
import ProfileEmail from "../assets/images/profile-email.png";
import ProfileUserIcon from "../assets/images/profile-user-icon.png";
import ProfileCall from "../assets/images/profile-call.png";

import ShortForums from "../assets/images/short-forums.png";
import ShorCareer from "../assets/images/short-career.png";
import ShortCounselors from "../assets/images/short-counselors.png";
import ProfileUser from "../assets/images/profile-user.png";
import SettingPro from "../assets/images/setting-pro.png";
import WalletPro from "../assets/images/wallet-pro.png";
import Logo from "../assets/images/logo.png";
import MyForum from "../assets/images/my-forum.png";
import MyForumBlue from "../assets/images/my-forum-blue.png";
import NotificationBlack from "../assets/images/notification-balck.png";
import NotificationBlue from "../assets/images/notification-blue.png";
import Careerpathblack from "../assets/images/Careerpath-balck.png";
import Careerpathblue from "../assets/images/Careerpath-blue.png";
import Skillsblack from "../assets/images/Skills-Black.png";
import Skillsblue from "../assets/images/Skills-blue.png";
import CertificationBlack from "../assets/images/Certification-black.png";
import CertificationBlue from "../assets/images/Certification-blue.png";
import careerBlack from "../assets/images/career-black.png";
import careerBlue from "../assets/images/career-blue.png";
import resourcesBlack from "../assets/images/resources-black.png";
import resourcesBlue from "../assets/images/resources-blue.png";
import mentorshipBlack from "../assets/images/mentorship.png";
import mentorshipBlue from "../assets/images/mentorship-blue.png";
import jobsBlack from "../assets/images/jobs-black.png";
import jobsBlue from "../assets/images/jobs-blue.png";
import walletBlack from "../assets/images/wallet-black.png";
import walletBlue from "../assets/images/wallet-blue.png";
import myapplicationBlack from "../assets/images/myapplication-black.png";
import myapplicationBlue from "../assets/images/myapplication-blue.png";
import forum from "../assets/images/forum.png";
import notification from "../assets/images/notification.png";
import One from "../assets/images/1.png";
import Two from "../assets/images/2.png";
import Three from "../assets/images/3.png";
import VerifiedIcon from "../assets/images/Verified-icon.png";
import CertifiedLogo from "../assets/images/certificate-logo.png";

export const Images = {
  CertifiedLogo: String(CertifiedLogo),
  VerifiedIcon: String(VerifiedIcon),
  Two: String(Two),
  Three: String(Three),
  One: String(One),
  notification: String(notification),
  forum: String(forum),
  Share: String(Share),
  CounsellerImg1: String(CounsellerImg1),
  CounsellerImg2: String(CounsellerImg2),
  CounsellerImg3: String(CounsellerImg3),
  Comments: String(Comments),
  Upvote: String(Upvote),
  HelpPro: String(HelpPro),
  abhinavk: String(abhinavk),
  ProfileEmail: String(ProfileEmail),
  ProfileUserIcon: String(ProfileUserIcon),
  ProfileCall: String(ProfileCall),
  ShortForums: String(ShortForums),
  ShorCareer: String(ShorCareer),
  ShortCounselors: String(ShortCounselors),
  ProfileUser: String(ProfileUser),
  SettingPro: String(SettingPro),
  WalletPro: String(WalletPro),
  Logo: String(Logo),
  MyForum: String(MyForum),
  MyForumBlue: String(MyForumBlue),
  NotificationBlack: String(NotificationBlack),
  NotificationBlue: String(NotificationBlue),
  Careerpathblack: String(Careerpathblack),
  Careerpathblue: String(Careerpathblue),
  Skillsblack: String(Skillsblack),
  Skillsblue: String(Skillsblue),
  CertificationBlack: String(CertificationBlack),
  CertificationBlue: String(CertificationBlue),
  careerBlack: String(careerBlack),
  careerBlue: String(careerBlue),
  resourcesBlack: String(resourcesBlack),
  resourcesBlue: String(resourcesBlue),
  mentorshipBlack: String(mentorshipBlack),
  mentorshipBlue: String(mentorshipBlue),
  jobsBlack: String(jobsBlack),
  jobsBlue: String(jobsBlue),
  walletBlack: String(walletBlack),
  walletBlue: String(walletBlue),
  myapplicationBlack: String(myapplicationBlack),
  myapplicationBlue: String(myapplicationBlue),
};
