import About from "../feature/about/About";
import AdvertiseOnXaphal from "../feature/about/AdvertiseOnXaphal";
import Homepage from "./../feature/login/Homepage";
import CareerPath from "./../feature/about/CareerPath";
import StudentAmbassador from "../feature/about/StudentAmbassador";
import ApplyStudentAmbassador from "../feature/about/ApplyStudentAmbassador";
import JoinTeam from "../feature/about/JoinTeam";
import ApplyTeam from "../feature/about/ApplyTeam";
import CollegePartnership from "../feature/about/CollegePartnership";
import ApplyPartnership from "../feature/about/ApplyPartnership";
import Counseller from "../feature/counseller/Counseller";
import Signup from "../feature/counseller/Signup";
import Recruiter from "../feature/recruiter/Recruiter";
import RecruiterSignup from "../feature/recruiter/RecruiterSignup";
import BookYourSpace from "../feature/advertise/BookYourSpace";
import PrivacyPolicy from "../feature/about/PrivacyPolicy";
import TermsAndCondition from "../feature/about/TermsAndCondition";
import InvestorRelation from "../feature/investor/InvestorRelation";
import RequestMeeting from "../feature/investor/RequestMeeting";
import Signup1 from "../feature/login/Signup1";
import Signup2 from "../feature/login/Signup2";
import Signup3 from "../feature/login/Signup3";
import Signup4 from "../feature/login/Signup4";
import Certification from "../feature/certification/Certification";
import MentorSignup from "../feature/counseller/MentorSignup";
import {Images} from "./../constants/Images";
import HomepageAfter from "./../feature/myforum/Homepage";
import CareerPathAuth from "./../feature/careerpath/CareerPath";
import CareerPathDetail from "../feature/careerpath/CareerPathDetail";
import Skills from "../feature/skills/Skills";
import PopularCareerPath from "../feature/careerpath/PopularCareerPath";
import EditBasicInfo from "../feature/profile/student/EditBasicInfo";
import EditDegree from "../feature/profile/student/EditDegree";
import EditCareerPaths from "../feature/profile/student/EditCareerPaths";
import EditSkills from "../feature/profile/student/EditSkills";
import EditCounseller from "../feature/profile/counseller/EditCounseller";
import EditRecruiter from "../feature/recruiter/EditRecruiter";
import EditMentor from "../feature/profile/mentor/EditMentor";
import ViewProfile from "../feature/profile/student/ViewProfile";
import Setting from "../feature/login/Setting";
import AddCertification from "../feature/certification/AddCertification";

import DiscoverCertification from "../feature/certification/DiscoverCertification";

import CareerCounseller from "../feature/counseller/CareerCounseller";
import CounsellerDetails from "../feature/counseller/CounsellerDetails";
import ServicesList from "../feature/services/ServicesList";
import ManageRequests from "../feature/request/ManageRequest";
import StudentRequest from "../feature/request/StudentRequest";
import ChangePassword from "../feature/login/ChangePassword";
import WorkExperience from "../feature/profile/student/WorkExperience";
import CompletedOrders from "../feature/orders/CompletedOrders";
import Jobs from "../feature/jobs/Jobs";
import ForgetPassword from "../feature/login/ForgetPassword";
import Services from "../feature/counseller/Services";
import {useRoutes, Outlet, Navigate} from "react-router-dom";
import NewNavbar from "../component/NewNavbar";
import Footer from "../component/Footer";
import UserProfile from "../feature/profile/userProfile/UserProfile";
import PublicUsers from "../feature/publicPage/PublicUsers";
import PublicQuestion from "../feature/publicPage/PublicQuestion";
import Notifications from "../feature/notification/Notifications";
import UserProfileLayout from "../feature/profile/userProfile/UserProfileLayout";

import SettingIcon from "../assets/svg-images/setting-icon.svg";
import serviceIcons from "../assets/svg-images/service-icon.svg";
import MeetingRequestIcon from "../assets/svg-images/meeting-request-icon.svg";
import MyWalletIcon from "../assets/svg-images/my-wallet.svg";
import HelpIcon from "../assets/svg-images/question-fill-icon.svg";
import ResourcesIcon from "../assets/svg-images/book-check-duotone.svg";
import CertificateIcon from "../assets/svg-images/certificate-icon.svg";
import Help from "../feature/help/Help";
import Resources from "../feature/resources/Resources";
import Faq from "../feature/faq/Faq";
import MyWallet from "../feature/mywallet/MyWallet";
import PublicForumDetails from "../feature/publicPage/PublicForumDetails";
import PublicForumAndPrivate from "../feature/profile/userProfile/PublicForumAndPrivate";
import PrivateLayout from "../layouts/PrivateLayout";
import NewSignup3Form from "../feature/login/NewSignup3Form";
import NewSignup2Form from "../feature/login/NewSignup2Form";
import NewSignup1Form from "../feature/login/NewSignup1Form";
import NotFound from "../feature/login/NotFound";
import FestivalLayout from "../layouts/FestivalLayout";
import BeforeJoinCheck from "../feature/request/BeforeJoinCheck";
import XfactorHomepage from "../feature/login/XfactorHomepage";
import ChatContainerRightSideBar from "../feature/chatBox/ChatMsgBox";
import MessageLayout from "../layouts/MessageLayout";
import {SvgImages} from "./../constants/SvgImages";
import ChatLayout from "../feature/chatBox/ChatLayout";
// import PublicOpportunityDetails from "../feature/publicPage/PublicOpportunityDetails";
import OpportunityDetails from "../feature/myforum/OpportunityDetails";
import NewHomePage from "../feature/login/NewHomePage";
import NewLayout from "../layouts/NewLayout";
import LatestHomePage from "../feature/LatestHomePage";

const isAuthorized = localStorage.getItem("token");
// const isAuthorized = false;

export const newRoutes = [
  {
    path: "/",
    // element: <NewHomePage />, // main login landing page
    element: <LatestHomePage />,
  },
  {
    path: "/index.html",
    element: <Navigate to="/" />,
  },
  {
    path: "*",
    element: <NotFound />,
    index: false,
  },
]

export const authRoutes = [
  {
    path: "/",
    // element: <NewHomePage />, // main login landing page
    element: <LatestHomePage />,
  },
  {
    path: "/xfactorfestivals",
    element: <XfactorHomepage />,
  },
  {
    path: "/index.html",
    element: <Navigate to="/" />,
  },
  {
    path: "*",
    element: <NotFound />,
    index: false,
  },

  // {
  //   path: ":name",
  //   element: <UserProfileLayout />,
  //   children: [{ index: true, element: <UserProfile /> }],
  // },

  // {
  //   path: "/about",
  //   element: <About />,
  // },
  // {
  //   path: "/privacy-policy",
  //   element: <PrivacyPolicy />,
  // },
  // {
  //   path: "/terms-and-condition",
  //   element: <TermsAndCondition />,
  // },
  {
    path: "/public",
    // element: <PublicUsers />,
    children: [
      {
        // path: "/",
        element: <UserProfileLayout />,
        index: true,
      },
      {
        path: "questions",
        element: <PublicQuestion />,
      },
      {
        path: "question/:id/:title",
        element: <PublicForumDetails />,
      },
      //{
      //  path: "opportunity/:id/:title",
      //  element: <PublicOpportunityDetails />,
      //},
      // {
      //     path: "/career-paths/:id/:name",
      //     element: <CareerPathDetail />,
      // },
      {
        path: "users",
        element: <PublicUsers />,
      },
      {
        path: "user/:name",
        element: <UserProfile />,
      },
    ],
  },
  // {
  //   path: "/career-paths/:id/:title",
  //   element: <CareerPathDetail />,
  //   name: "Career Paths",
  //   hidden: true,
  // },

  // {
  //   path: "/about",
  //   element: <About />,
  // },

  {
    path: "/book-your-space",
    element: <BookYourSpace />,
  },

  {
    path: "/apply/student-ambassador",
    element: <ApplyStudentAmbassador />,
  },

  {
    path: "/apply/team",
    element: <ApplyTeam />,
  },

  {
    path: "/apply/partnership",
    element: <ApplyPartnership />,
  },

  {
    path: "/signup/counsellor",
    element: <Signup />,
  },
  {
    path: "/signup/mentor",
    element: <MentorSignup />,
  },

  {
    path: "/signup/recruiter",
    element: <RecruiterSignup />,
  },
  // {
  //   path: "/privacy-policy",
  //   element: <PrivacyPolicy />,
  // },
  // {
  //   path: "/terms-and-condition",
  //   element: <TermsAndCondition />,
  // },

  {
    path: "/request-a-meeting",
    element: <RequestMeeting />,
  },
  {
    path: "/new-sign-up/1",
    element: <NewSignup1Form />,
  },
  {
    path: "/new-sign-up/2",
    element: <NewSignup2Form />,
  },
  {
    path: "/new-sign-up/3",
    element: <NewSignup3Form />,
  },
  {
    path: "/sign-up/1",
    element: <Signup1 />,
  },
  {
    path: "/sign-up/2",
    element: <Signup2 />,
  },
  {
    path: "/sign-up/3",
    element: <WorkExperience />,
  },
  {
    path: "/sign-up/4",
    element: <Signup3 />,
  },
  {
    path: "/sign-up/5",
    element: <Signup4 />,
  },
  {
    path: "/popular-career-paths",
    element: <PopularCareerPath />,
  },
];

export const afterRoutes = [
  {
    path: "/forget-password",
    element: <ForgetPassword />,
    hidden: true,
    visibleFor: "all",
  },
  {
    path: "/homepage",
    element: <HomepageAfter />,
    name: "Home",
    img1: "assets/images/my-forum.png",
    img2: "assets/images/my-forum-blue.png",
    hidden: true,
    visibleFor: "all",
  },
  {
    path: "/certification",
    element: <Certification />,
    name: "Certification",
    img1: CertificateIcon,
    img2: CertificateIcon,
    hidden: false,
    visibleFor: "student",
  },

  {
    path: "/meeting-requests",
    element: <StudentRequest />,
    name: "meeting requests",
    img1: MeetingRequestIcon,
    img2: MeetingRequestIcon,
    hidden: false,
    // visibleFor: "student",
  },
  {
    path: "/requests",
    element: <ManageRequests />,
    name: "Manage Requests",
    img1: MeetingRequestIcon,
    img2: MeetingRequestIcon,
    hidden: false,
    visibleFor: "mentor/counseller",
  },

  {
    path: "/resources",
    element: <Resources />,
    name: "resources",
    img1: ResourcesIcon,
    img2: ResourcesIcon,
    hidden: false,
    visibleFor: "student",
  },
  {
    path: "/jobs",
    element: <Jobs />,
    name: "jobs",
    hidden: true,
    visibleFor: "student",
  },

  {
    path: "/help",
    element: <Help />,
    name: "help",
    img1: HelpIcon,
    img2: HelpIcon,
    hidden: false,
    visibleFor: "student",
  },
  // {
  //   path: "/faq",
  //   //element: <CareerPathAuth />,
  //   name: "FAQ",
  //   img1: FaqIcon,
  //   img2: FaqIcon,
  //   hidden: false,
  //   visibleFor: "student",
  // },

  {
    path: "/mywallet",
    element: <MyWallet />,
    name: "My Wallet",
    img1: MyWalletIcon,
    img2: MyWalletIcon,
    hidden: false,
    visibleFor: "student",
  },

  {
    path: "/career-paths",
    element: <CareerPathAuth />,
    name: "Career Paths",
    img1: "assets/images/Careerpath-balck.png",
    img2: "assets/images/Careerpath-blue.png",
    hidden: true,
    visibleFor: "student",
  },

  {
    path: "/discover-certification",
    element: <DiscoverCertification />,
    name: "Career Paths",
    img1: "assets/images/Careerpath-balck.png",
    img2: "assets/images/Careerpath-blue.png",
    hidden: true,
    visibleFor: "student",
  },

  // {
  //   path: "/career-paths/:id/:name",
  //   element: <CareerPathDetail />,
  //   name: "Career Paths",
  //   hidden: true,
  // },
  {
    path: "/skills",
    element: <Skills />,
    name: "Skills",
    img1: "assets/images/Skills-Black.png",
    img2: "assets/images/Skills-blue.png",
    hidden: true,
    visibleFor: "student",
  },

  {
    path: "/student/request",
    element: <StudentRequest />,
    name: "Requests",
    img1: "assets/images/Unselected.png",
    img2: "assets/images/Selected.png",
    hidden: true,
    visibleFor: "student",
  },
  {
    path: "/services",
    element: <ServicesList />,
    name: "services",
    img1: serviceIcons,
    img2: serviceIcons,
    hidden: false,
    visibleFor: "mentor/counseller",
  },
  {
    path: "/settings",
    element: <Setting />,
    name: "settings",
    img1: SettingIcon,
    img2: SettingIcon,
    hidden: false,
    visibleFor: "all",
  },
  {
    path: "/add-certification",
    element: <AddCertification />,
    name: "Add Certification",
    img1: Images.CertificationBlack,
    img2: Images.CertificationBlue,
    hidden: true,
  },
  {
    path: "/certification/:id",
    element: <AddCertification />,
    name: "Edit Certification",
    img1: Images.CertificationBlack,
    img2: Images.CertificationBlue,
    hidden: true,
  },
  {
    path: "/student/edit-profile/1",
    element: <EditBasicInfo />,
    name: "Edit Basic Info",
    img1: Images.MyForum,
    img2: Images.MyForumBlue,
    hidden: true,
  },
  {
    path: "/student/edit-profile/2",
    element: <EditDegree />,
    name: "Edit Student Degree",
    img1: Images.MyForum,
    img2: Images.MyForumBlue,
    hidden: true,
  },
  {
    path: "/student/edit-profile/experience",
    element: <WorkExperience />,
    name: "Edit Work experience",
    img1: Images.MyForum,
    img2: Images.MyForumBlue,
    hidden: true,
  },
  {
    path: "/student/edit-profile/3",
    element: <EditCareerPaths />,
    name: "Edit Student Career Paths",
    img1: Images.MyForum,
    img2: Images.MyForumBlue,
    hidden: true,
  },
  {
    path: "/student/edit-profile/4",
    element: <EditSkills />,
    name: "Edit Student Skills",
    img1: Images.MyForum,
    img2: Images.MyForumBlue,
    hidden: true,
  },
  {
    path: "/counseller/edit-profile",
    element: <EditCounseller />,
    name: "Edit Counseller",
    img1: Images.MyForum,
    img2: Images.MyForumBlue,
    hidden: true,
  },
  {
    path: "/recruiter/edit-profile",
    element: <EditRecruiter />,
    name: "Edit Recruiter",
    img1: Images.MyForum,
    img2: Images.MyForumBlue,
    hidden: true,
  },
  {
    path: "/mentor/edit-profile",
    element: <EditMentor />,
    name: "Edit Mentor",
    img1: Images.MyForum,
    img2: Images.MyForumBlue,
    hidden: true,
  },
  {
    path: "/view-profile",
    element: <ViewProfile />,
    name: "View Profile",
    img1: Images.MyForum,
    img2: Images.MyForumBlue,
    hidden: true,
  },
  {
    path: "/setting",
    element: <Setting />,
    name: "View Profile",
    img1: Images.MyForum,
    img2: Images.MyForumBlue,
    hidden: true,
  },
  {
    path: "/career-coach",
    element: <CareerCounseller />,
    name: "career counsellor",
    img1: "assets/images/career-black.png",
    img2: "assets/images/career-blue.png",
    hidden: true,
    visibleFor: "student",
    children: [
      {
        path: ":userType/:serviceId",
        element: <Services />,
        index: true,
      },
    ],
  },

  {
    path: "/career-counsellor/:userType/:id/:skill",
    element: <CounsellerDetails />,
    name: "career-counseller",
    img1: "assets/images/mentorship.png",
    img2: "assets/images/mentorship-blue.png",
    hidden: true,
  },
  // {
  //   path: "/mentorship",
  //   element: <Mentorship />,
  //   name: "mentorship",
  //   img1: "assets/images/mentorship.png",
  //   img2: "assets/images/mentorship-blue.png",
  //   hidden: true,
  //   visibleFor: "student",
  // },
  // {
  //   path: "/mentorship",
  //   element: <Mentorship />,
  //   name: "mentorship",
  //   img1: "assets/images/mentorship.png",
  //   img2: "assets/images/mentorship-blue.png",
  //   hidden: false,
  //   visibleFor: "student",
  // },
  // {
  //   path: "/mentorship/:id/:skill/",
  //   element: <MentorshipDetails />,
  //   name: "mentorship",
  //   img1: "assets/images/mentorship.png",
  //   img2: "assets/images/mentorship-blue.png",
  //   hidden: true,
  // },
  // {
  //   path: "/career-counseller/:id/:skill",
  //   element: <MentorshipDetails />,
  //   name: "mentorship",
  //   img1: "assets/images/mentorship.png",
  //   img2: "assets/images/mentorship-blue.png",
  //   hidden: true,
  // },

  {
    path: "/change-password",
    element: <ChangePassword />,
    name: "change password",
    hidden: true,
  },
  {
    path: "/completedOrders",
    element: <CompletedOrders />,
    name: "orders",
    hidden: true,
    visibleFor: null,
  },
  {
    path: "/notification",
    element: <Notifications />,
    name: "notification",
    hidden: true,
    visibleFor: null,
  },
  // {
  //   path: "/chat",
  //   element: <ChatContainerRightSideBar />,
  //   name: "chat",
  //   hidden: true,
  //   visibleFor: null,
  // },
  // {
  //   path: ":name",
  //   element: <UserProfile />,
  //   hidden: true,
  //   visibleFor: null,
  // },
];

export const FestivalRoutes = [
  {
    path: `/festival/:name/:id/:session/:role`,
    element: <BeforeJoinCheck />,
    name: "festival",
    img1: MeetingRequestIcon,
    img2: MeetingRequestIcon,
    hidden: false,
    // visibleFor: "student", .trim().replace(/\s+/g, '-')
    //  https://xaphal.com/festival?festival_name="Ann"&meetingId=123&session=moderatorPW&role=mentor
  },
];

export const MessageRoutes = [
  {
    path: `/chat`,
    element: <ChatLayout />,
    name: "messaging",
    img1: SvgImages.MessagingIcon,
    img2: SvgImages.MessagingIcon,
    hidden: false,
  },
];

const PublicLayout = () => {
  return false ? (
    <Navigate to="/homepage" />
  ) : (
    <Outlet />
  )
  // : (
  //   <div id="beforeLoginScreen">
  //     <NewNavbar />
  //       <Outlet />
  //     <Footer />
  //   </div>
  // );
};


export default function Router() {
  let element = useRoutes([
    {
      element: <PublicLayout />,
      // children: authRoutes, // it is currently on hold
      children: newRoutes,
    },
    // {
    //   element: <NewLayout />,
    //   children: newRoutes,
    // },
    // {
    //   element: <PrivateLayout />,
    //   children: afterRoutes,
    // },
    // {
    //   element: <FestivalLayout />,
    //   children: FestivalRoutes,
    // },
    // {
    //   element: <MessageLayout />,
    //   children: MessageRoutes,
    // },
    // {
    //   path: "latest-homepage",
    //   element: <LatestHomePage />
    // },
    // {
    //   element: <UserProfileLayout />,
    //   children: [
    //     {
    //       path: ":name",
    //       element: <UserProfile />,
    //     },
    //     {
    //       path: `/question/:id/:title`,
    //       element: <PublicForumAndPrivate />,
    //     },
    //     {
    //       path: `/opportunity/:title/:id`,
    //       element: <OpportunityDetails />,
    //     },
    //     {
    //       path: "/about",
    //       element: <About color_code="#141414" search_show="false" />,
    //     },
    //     {
    //       path: "/faq",
    //       element: <Faq />,
    //     },
    //     {
    //       path: "/privacy-policy",
    //       element: <PrivacyPolicy />,
    //     },

    //     {
    //       path: "/career-path-xaphal",
    //       element: <CareerPath />,
    //     },
    //     {
    //       path: "/student-ambassador",
    //       element: <StudentAmbassador />,
    //     },
    //     {
    //       path: "/join-team",
    //       element: <JoinTeam />,
    //     },
    //     {
    //       path: "/college-partnership",
    //       element: <CollegePartnership />,
    //     },
    //     {
    //       path: "/counsellor-mentor",
    //       element: <Counseller />,
    //     },
    //     {
    //       path: "/advertise-on-xaphal",
    //       element: <AdvertiseOnXaphal />,
    //     },
    //     {
    //       path: "/investor-relations",
    //       element: <InvestorRelation />,
    //     },
    //     {
    //       path: "/recruiter",
    //       element: <Recruiter />,
    //     },
    //     {
    //       path: "/terms-and-condition",
    //       element: <TermsAndCondition />,
    //     },
    //     {
    //       path: "/career-paths/:id/:name",
    //       element: <CareerPathDetail />,
    //     },


    //   ],
    // },
  ]);
  return element;
}
