import axios from "axios";
import {toast} from "react-toastify";
// const BASE_URL = "https://api.xaphal.com/api/";  /* prod url */
// const BASE_URL = "http://65.0.92.54:4000/api/";  /* dev url */
// const BASE_URL = "http://localhost:4000/api/";

//  or second method

const BASE_URL = process.env.REACT_APP_BACKEND_URL
// console.log('handler', process.env.REACT_APP_BACKEND_URL);
export const postFormDataNoToken = async (url = "", data = {}) => {
  try {
    const formData = new FormData();

    for(let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post(BASE_URL + url, formData);

    return response.data;
  } catch(error) {
    toast.error(error.message);
    return null;
  }
};
export const postFormData = async (url = "", data = {}) => {
  try {
    const token = localStorage.getItem("token");

    const formData = new FormData();

    for(let key in data) {
      if(typeof data[key] === "object") {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    }
    const response = await axios.post(BASE_URL + url, formData, {
      headers: {token: token ? token : ""},
    });

    return response.data;
  } catch(error) {
    toast.error(error.message);
    return null;
  }
};
export const patchFormData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const formData = new FormData();

    for(let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.patch(BASE_URL + url, formData, {
      headers: {token},
    });

    return response.data;
  } catch(error) {
    toast.error(error.message);
    return null;
  }
};
export const postRawData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.post(BASE_URL + url, data, {
      headers: {
        token,
      },
    });

    return response.data;
  } catch(error) {
    toast.error(error.message);
    return null;
  }
};

export const getDataNoToken = async (url = "", data = {}) => {
  try {
    const response = await axios.get(BASE_URL + url, {
      params: data,
    });

    return response.data;
  } catch(error) {
    return null;
  }
};

export const getDataforUrl = async (url = "", data = "") => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.get(BASE_URL + url + `/${data}`, {
      headers: {
        token,
      },
    });

    return response.data;
  } catch(error) {
    return null;
  }
};

export const getData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.get(BASE_URL + url, {
      params: data,
      headers: {
        token: token ? token : "",
      },
    });

    return response.data;
  } catch(error) {
    return null;
  }
};

export const getDataStringify = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.get(BASE_URL + url, {
      params: data,

      headers: {
        token,
      },
    });

    return response.data;
  } catch(error) {
    return null;
  }
};

export const putFormData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");
    const formData = new FormData();

    for(let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.put(BASE_URL + url, formData, {
      headers: {
        token,
      },
    });

    return response.data;
  } catch(error) {
    return null;
  }
};

export const deleteData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");
    const formData = new FormData();

    for(let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.delete(BASE_URL + url, {
      headers: {
        token: token,
      },
      data: formData,
    });

    return response.data;
  } catch(error) {
    return null;
  }
};

export const getDataAdmin = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.get(BASE_URL + url, {
      params: data,
      headers: {
        token: token ? token : "",
      },
    });

    return response.data;
  } catch(error) {
    return null;
  }
};
