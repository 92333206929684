import "./App.css";
import {BrowserRouter, Route} from "react-router-dom";
import Router from "./routes";
import ScrollToTop from "./services/helper/ScrollToTop";
import {pathname} from "./constants";
import './assets/css/newStyle.css'
import './assets/css/styles.css'
import './assets/css/largeScreenStyle.css'
import './assets/css/mediumScreenStyle.css'
import './assets/css/smallScreenStyle.css'
import './assets/css/mobileScreenStyle.css'
import './assets/css/custom.css'
import NotFound from "./feature/login/NotFound";
import {hydrate, render} from "react-dom";

function App() {
  return (
    <>
      <BrowserRouter basename={`${pathname}/`}>
        <ScrollToTop>
          <Router />
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}


//const rootElement = document.getElementById("root");
//if(rootElement.hasChildNodes()) {
//  hydrate(<App />, rootElement);
//} else {
//  render(<App />, rootElement);
//}
//ServiceWorker.unregister()

export default App;
